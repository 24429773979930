import React, { useState } from 'react';
import { Button } from 'rsuite';

export default function SwitchButtons({
  updatePlaceholder,
  toggleAdvancedFilters,
  filtersProps,
}) {
  const SEARCH_MODE = {
    NORMAL: 'normal',
    PROCESS_NUMBER: 'nproc',
  };

  const handleSearchMode = (value) => {
    //TODO: Aqui os searchmode é mudado
    handleChangeButton(value);

    // change state
    filtersProps.changeSearchMode(value);

    // change urlParams
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('search_mode', value);

    let paramsStringDecode = decodeURIComponent(urlParams);

    // colocar na URL
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${paramsStringDecode}`,
    );
  };

  const [clickedButton, setClickedButton] = useState(SEARCH_MODE.NORMAL);

  const handleChangeButton = (buttonId) => {
    updatePlaceholder(buttonId);
    setClickedButton(buttonId);
  };

  return (
    <div className="button-input">
      <Button
        className={`btn-left ${clickedButton === SEARCH_MODE.NORMAL ? 'clicked' : 'unclicked'}`}
        onClick={() => {
          handleSearchMode(SEARCH_MODE.NORMAL);
          toggleAdvancedFilters(true);
        }}
      >
        <span>Buscar</span>
      </Button>
      <Button
        className={`btn-right ${clickedButton === SEARCH_MODE.PROCESS_NUMBER ? 'clicked' : 'unclicked'}`}
        onClick={() => {
          handleSearchMode(SEARCH_MODE.PROCESS_NUMBER);
          toggleAdvancedFilters(false);
        }}
      >
        <span>Busca por número de processo</span>
      </Button>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Popover, Tooltip, Whisper } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import DeleteModalMonitoring from '../Buttons/DeleteModalMonitoring';
import ShareModalMonitoring from '../Buttons/ShareModalMonitoring';
import SettingsModalMonitoring from '../Buttons/SettingsModalMonitoring';
import InformativeIcons from './InformativeIcons';
import { ArrowCircleDown, ArrowCircleRight2, ArrowDown2 } from 'iconsax-react';

const renderToggleMenu = (props, ref) => {
  return (
    <Button {...props} ref={ref} className="monitoring-card_btn-action">
      <FontAwesomeIcon icon={faEllipsisV} />
    </Button>
  );
};

const renderOldUpdatesButton = (props, ref) => {
  return (
    <Button {...props} ref={ref} className="monitoring-card_footer-old-updates">
      Atualizações antigas
      <ArrowDown2 />
    </Button>
  );
};
const MonitoringCard = ({
  monitoring,
  entities,
  shared,
  onDeleteMonitoring,
  onSharedWith,
  onEditMonitoring,
  onLastResults,
  getMonitoring,
  onLatestResults,
}) => {
  const [openOldUpdates, setOpenOldUpdates] = useState(false);
  let oldUpdates = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!oldUpdates.current?.contains(e.target)) {
        setOpenOldUpdates(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  const dateFormatted = (date) => {
    if (date) {
      const dt = date.split('-');
      return `${dt[2]}/${dt[1]}/${dt[0]}`;
    } else {
      return ``;
    }
  };

  return (
    <div className="monitoring-card monitoring-card-border" key={monitoring.id}>
      <div className="monitoring-card_header">
        <Whisper
          placement="bottomStart"
          speaker={
            <Tooltip className="monitoring-card_whisper">
              <p className="font-semibold monitoring-card_whisper-title">
                {monitoring.query}
              </p>

              {monitoring &&
              monitoring.search_results &&
              monitoring.search_results.length > 0 ? (
                <p>
                  {dateFormatted(monitoring.search_results[0].initial_date)} a{' '}
                  {dateFormatted(monitoring.search_results[0].final_date)}
                </p>
              ) : (
                <p>Nenhuma atualização</p>
              )}
            </Tooltip>
          }
        >
          <div>
            <div className="monitoring-card_header-title">
              <span className="font-medium">Termo:</span>
              <p className="font-bold">{monitoring.query}</p>
            </div>
            {monitoring &&
            monitoring.search_results &&
            monitoring.search_results.length > 0 ? (
              <>
                <span className="monitoring-card_title-update">
                  Últimas Decisões:{' '}
                </span>
                <span className="font-medium">
                  {dateFormatted(monitoring.search_results[0].initial_date)} a{' '}
                  {dateFormatted(monitoring.search_results[0].final_date)}
                </span>
              </>
            ) : (
              <span className="monitoring-card_title-update">
                Nenhuma atualização
              </span>
            )}
          </div>
        </Whisper>
        {!shared ? (
          <div className="monitoring-card_menu">
            <Dropdown
              placement="leftStart"
              renderToggle={renderToggleMenu}
              id="nav-menu-options"
            >
              <Dropdown.Item eventKey={1}>
                <SettingsModalMonitoring
                  monitoring={monitoring}
                  entities={entities}
                  onEditMonitoring={onEditMonitoring}
                  getMonitoring={getMonitoring}
                />
              </Dropdown.Item>
              <Dropdown.Item eventKey={2}>
                <ShareModalMonitoring
                  monitoring={monitoring}
                  onSharedWith={onSharedWith}
                  getMonitoring={getMonitoring}
                />
              </Dropdown.Item>
              <Dropdown.Item eventKey={3}>
                <DeleteModalMonitoring
                  monitoring={monitoring}
                  onDeleteMonitoring={onDeleteMonitoring}
                />
              </Dropdown.Item>
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className="monitoring-card_body">
        <InformativeIcons permissions={monitoring} />

        <div className="monitoring-card_body-scope">
          {monitoring.search_results[0]?.total_new_results ? (
            <div className="monitoring-card_body-scope_with-results">
              <div className="monitoring-card_body-scope_with-results-updates pt-1 pb-3">
                <div className="monitoring-card_body-scope_with-results-badge">
                  novo
                </div>
                <p>
                  {monitoring.search_results[0]?.total_new_results} resultado(s)
                </p>
              </div>
            </div>
          ) : (
            <div className="monitoring-body_without-results">
              Este monitoramento não teve atualizações
            </div>
          )}

          {monitoring.search_results[0]?.total_new_results ? (
            <div className="monitoring-card_footer">
              {monitoring &&
              monitoring.search_results &&
              monitoring.search_results.length > 1 ? (
                <div
                  className="monitoring-card_footer-old-updates_actions"
                  ref={oldUpdates}
                >
                  <button
                    className="monitoring-card_footer-old-updates font-medium"
                    onClick={() => setOpenOldUpdates(!openOldUpdates)}
                  >
                    <ArrowCircleDown size={20} color="#667085" />
                    Atualizações antigas
                  </button>
                  {openOldUpdates ? (
                    <div className="monitoring-card_footer-old-updates_actions-menu">
                      {monitoring.search_results
                        .slice(1, monitoring.search_results.length)
                        ?.map((result, index) => (
                          <div
                            className="monitoring-card_footer-old-updates_actions-menu-item"
                            key={index}
                          >
                            <span
                              className="monitoring-card_footer-old-updates_actions-menu-span"
                              key={index}
                              onClick={() =>
                                onLatestResults(monitoring, result)
                              }
                            >
                              {result.total_new_results} resultado(s) -{' '}
                              {dateFormatted(result.initial_date)} a{' '}
                              {dateFormatted(result.final_date)}
                            </span>
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              ) : (
                <span>Nenhuma atualização antiga</span>
              )}
              <button
                className="monitoring-card_footer-new-updates"
                onClick={() => onLastResults(monitoring)}
              >
                Ver atualizações
                <ArrowCircleRight2 size={20} color="#FFFFFF" />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MonitoringCard;

import React from 'react';
import PropTypes from 'prop-types';
import './TuriviusPieChart.scss';
import { ResponsivePie } from '@nivo/pie';

const TuriviusPieChart = ({
  id = undefined,
  className = '',
  style = {
    width: 400,
    height: 300,
  },
  data = [],
  valueFormat = undefined,
  margin = {
    top: 20,
    right: 80,
    bottom: 80,
    left: 80,
  },
  innerRadius = 0.5,
  padAngle = 0.7,
  cornerRadius = 3,
  activeOuterRadiusOffset = 8,
  borderWidth = 1,
  borderColor = {
    from: 'color',
    modifiers: [['darker', 0.2]],
  },
  arcLinkLabelsSkipAngle = 10,
  arcLinkLabelsTextColor = '#333333',
  arcLinkLabelsThickness = 2,
  arcLinkLabelsColor = {
    from: 'color',
  },
  arcLabelsSkipAngle = 10,
  arcLabelsTextColor = {
    from: 'color',
    modifiers: [['darker', 2]],
  },
  getColors = undefined,
  onClick = () => {},
  tooltip = undefined,
  isInteractive = true,
  legends = [
    {
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 56,
      itemsSpacing: 0,
      itemWidth: 100,
      itemHeight: 18,
      itemTextColor: '#999',
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 18,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemTextColor: '#000',
          },
        },
      ],
    },
  ],
  animate = true,
  ...params
}) => (
  <div
    id={id}
    data-testid={`pie-chart-${id}`}
    className={`turivius-pie-chart ${className}`}
    style={style}
  >
    <ResponsivePie
      data={data}
      valueFormat={valueFormat}
      margin={margin}
      innerRadius={innerRadius}
      padAngle={padAngle}
      cornerRadius={cornerRadius}
      activeOuterRadiusOffset={activeOuterRadiusOffset}
      borderWidth={borderWidth}
      borderColor={borderColor}
      arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
      arcLinkLabelsTextColor={arcLinkLabelsTextColor}
      arcLinkLabelsThickness={arcLinkLabelsThickness}
      arcLinkLabelsColor={arcLinkLabelsColor}
      arcLabelsSkipAngle={arcLabelsSkipAngle}
      arcLabelsTextColor={arcLinkLabelsTextColor}
      legends={legends}
      colors={getColors}
      onClick={onClick}
      tooltip={tooltip}
      isInteractive={isInteractive}
      animate={animate}
      {...params}
    />
  </div>
);

TuriviusPieChart.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.array,
  valueFormat: PropTypes.func,
  margin: PropTypes.object,
  innerRadius: PropTypes.number,
  padAngle: PropTypes.number,
  cornerRadius: PropTypes.number,
  activeOuterRadiusOffset: PropTypes.number,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.object,
  arcLinkLabelsSkipAngle: PropTypes.number,
  arcLinkLabelsTextColor: PropTypes.string,
  arcLinkLabelsThickness: PropTypes.number,
  arcLinkLabelsColor: PropTypes.object,
  arcLabelsSkipAngle: PropTypes.number,
  arcLabelsTextColor: PropTypes.object,
  legends: PropTypes.array,
  getColors: PropTypes.func,
  onClick: PropTypes.func,
  tooltip: PropTypes.func,
  isInteractive: PropTypes.bool,
  animate: PropTypes.bool,
};

export default TuriviusPieChart;

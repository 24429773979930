import { useEffect } from 'react';
import SearchCollection from '../Collections/SearchCollection';
import { useState } from 'react';
import CollectionCenterCard from '../Collections/CollectionCenterCard';
import CollectionOrderBy from '../Collections/CollectionOrderBy';
import FilterOrdered from '../Collections/FilterOrdered';

const MyCollectionSession = ({ getCollections }) => {
  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-last_update');
  const [ordenarPor, setOrdenarPor] = useState(
    'Data de Atualização (mais recentes)',
  );

  const orderMapping = {
    'Ordem Alfabética (A-Z)': 'name',
    'Ordem Alfabética (Z-A)': '-name',
    'Data de Atualização (mais recentes)': '-last_update',
    'Data de Atualização (mais antigos)': 'last_update',
  };

  function onSearch(value = filter, page = 1, order = ordering) {
    setLoading(true);
    getCollections(
      'my_collections',
      value,
      page,
      order,
      (data) => {
        setCollections(data);
        setLoading(false);
      },
      setLoading,
    );
  }

  function handleOrderChange(option) {
    const newOrdering = orderMapping[option];
    setOrdenarPor(option);
    setOrdering(newOrdering);
    onSearch(filter, page, newOrdering);
  }

  function onChangePage(page) {
    setPage(page);
    getCollections(
      'my_collections',
      filter,
      page,
      ordering,
      setCollections,
      setLoading,
    );
  }

  function onCleanFilter() {
    setFilter('');
    getCollections(
      'my_collections',
      '',
      page,
      ordering,
      setCollections,
      setLoading,
    );
  }

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <div style={{ padding: ' 0 24px 24px 24px' }}>
      <SearchCollection
        title="Minhas Coleções"
        setFilter={setFilter}
        onSearch={onSearch}
        filter={filter}
        onChangeFilter={setFilter}
        id="my-collections"
      />
      <FilterOrdered
        ordenarPor={ordenarPor}
        setOrdenarPor={handleOrderChange}
        setFilter={setFilter}
      />

      <CollectionCenterCard
        loading={loading}
        collections={collections}
        page={page}
        filter={filter}
        shared={true}
        onCleanFilter={onCleanFilter}
        onChangePage={onChangePage}
        onSearch={onSearch}
      />
    </div>
  );
};

export default MyCollectionSession;

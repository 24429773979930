import React, { useEffect, useState } from 'react';
import SearchCollection from '../Collections/SearchCollection';
import CollectionCenterCard from '../Collections/CollectionCenterCard';
import CollectionOrderBy from '../Collections/CollectionOrderBy';
import FilterOrdered from '../Collections/FilterOrdered';

const PublicToCompanySession = ({ getCollections }) => {
  const group = 'public_to_company';

  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState('');
  const [haveCollections, setHaveCollections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-last_update');
  const [ordenarPor, setOrdenarPor] = useState(
    'Data de Atualização (mais recentes)',
  );

  const orderMapping = {
    'Ordem Alfabética (A-Z)': 'name',
    'Ordem Alfabética (Z-A)': '-name',
    'Data de Atualização (mais recentes)': '-last_update',
    'Data de Atualização (mais antigos)': 'last_update',
  };

  function sortByOrder(data, order) {
    return [...data].sort((a, b) => {
      if (order.includes('name')) {
        return order === 'name'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        const dateA = new Date(a.last_update);
        const dateB = new Date(b.last_update);
        return order === '-last_update' ? dateB - dateA : dateA - dateB;
      }
    });
  }

  function onSearch(value = filter, order = ordering) {
    setLoading(true);
    getCollections(
      group,
      value,
      page,
      order,
      (data) => {
        const sortedData = sortByOrder(data.results, order);
        setCollections({ ...data, results: sortedData });
        setHaveCollections(sortedData.length > 0);
        setLoading(false);
      },
      setLoading,
      setHaveCollections,
    );
  }

  function handleOrderChange(option) {
    const newOrdering = orderMapping[option];
    setOrdenarPor(option);
    setOrdering(newOrdering);
    onSearch(filter, newOrdering);
  }

  function onCleanFilter() {
    setFilter('');
    getCollections(
      group,
      '',
      page,
      ordering,
      setCollections,
      setLoading,
      setHaveCollections,
    );
  }

  function onChangePage(page) {
    setPage(page);
    getCollections(
      group,
      filter,
      page,
      ordering,
      setCollections,
      setLoading,
      setHaveCollections,
    );
  }

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <div style={{ padding: ' 0 24px 24px 24px' }}>
      <SearchCollection
        noCreate
        title="Coleções Compartilhadas com o Escritório"
        setFilter={setFilter}
        onSearch={onSearch}
        filter={filter}
        onChangeFilter={setFilter}
        id="company-collections"
      />
      <FilterOrdered
        ordenarPor={ordenarPor}
        setOrdenarPor={handleOrderChange}
        setFilter={setFilter}
      />
      <CollectionCenterCard
        onSearch={onSearch}
        loading={loading}
        collections={collections}
        page={page}
        filter={filter}
        onCleanFilter={onCleanFilter}
        onChangePage={onChangePage}
      />
    </div>
  );
};

export default PublicToCompanySession;

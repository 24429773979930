import { useMemo, useState } from 'react';
import { Dropdown, Tooltip, Whisper } from 'rsuite';
import { Button } from '../../../../../ds-components';
import { Setting2 } from 'iconsax-react';
import './CollectionHeader.scss';
import { exportCollectionToPDF } from '../../../../../services/collections';
import ManagePeopleCollectionButton from '../ManagePeopleCollectionButton/ManagePeopleCollectionButton';
import ShareCollectionWithCompanyButton from '../ShareCollectionWithCompanyButton/ShareCollectionWithCompanyButton';
import EditCollectionButton from '../EditCollectionButton/EditCollectionButton';
import RemoveCollectionButton from '../RemoveCollectionButton/RemoveCollectionButton';
import { updateCheckllist } from '../../../../../services/checklist';
import { ExportReport } from './ExportReport';
import DrawerCollection from '../ModalCollection/DrawerCollection';
import { useHistory, useLocation } from 'react-router-dom';

type CollectionHeaderProps = {
  collection: any;
  reloadAction: any;
};

const renderToggleMenu = (props: any, ref: any) => {
  return (
    <Whisper
    placement="top"  
    speaker={<Tooltip>Configurações</Tooltip>} 
  >
    <div
      className="button-shared-style"
      {...props}
      ref={ref}
      size="xs"
    >
      <Setting2 size={24} color="#475467" />
      <span> Opções</span>
    </div>
  </Whisper>
  );
};

const CollectionHeader = ({
  collection,
  reloadAction,
}: CollectionHeaderProps) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openDrawer = queryParams.get('openDrawer');
  const [isDrawerOpen, setDrawerOpen] = useState(openDrawer === 'true');

  const handleOpen = () => {
    setDrawerOpen(true);
    history.push({
      pathname: window.location.pathname,
      search: '?openDrawer=true',
    });
  };

  const updTour = async () => {
    const check = JSON.parse(localStorage.getItem('checklist') as string);
    if (!check?.trail?.report) {
      // await updateCheckllist(check?.id, 'report');
    }
  };

  const handleClickExport = async (collectionId: any, isDefault: boolean) => {
    try {
      await exportCollectionToPDF(
        userId,
        collectionId,
        collection.name,
        isDefault,
      );
      updTour();
    } catch (error) {
      console.error(error);
    }
  };

  const userId = useMemo(() => localStorage.getItem('user'), []);

  if (!collection) return <div></div>;

  return (
    <div>
      
      {/* <div className="banner-collection-header">
        <div className="title-banner">
          <img
            className="icon-stars"
            src="/stars-group.png"
            alt="Ícone de estrelas"
          />
          <h1>Turivius IA</h1>
          <span>beta</span>
        </div>

        <span>
          Otimize seu tempo e faça qualquer pergunta sua coleção. A IA da
          turivius te ajuda{' '}
        </span>

        <button className="banner-collection-button" onClick={handleOpen}>
          <img
            className="stars-icon-button"
            src="/stars-icon-button.png"
            alt="Ícone de estrelas"
          />
          <span>Use IA na sua coleção</span>
        </button>
      </div> */}


      <div className="collection-header-content">
        <div className="header_description">
          <h6 className="font-semibold">Descrição:</h6>
          {collection.description !== '' ? (
            <p className="description">{collection.description}</p>
          ) : (
            <p className="no-description">
              nenhuma descrição adicionada a essa coleção
            </p>
          )}
        </div>

        <div className="header_actions">
          <Dropdown
            placement="bottomStart"
            renderToggle={renderToggleMenu}
            id="nav-menu-options"
          >
            <Dropdown.Item>
              <ShareCollectionWithCompanyButton
                //@ts-ignore
                publicToCompany={collection.public_to_company}
                collection={collection}
                collectionId={collection.id}
                collectionTitle={collection.name}
                context={'Single Collection'}
                onSearch={reloadAction}
                style={{
                  margin: 'auto',
                  padding: '14px 4px',
                }}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <EditCollectionButton
                collectionId={collection.id}
                style={{ width: '100%' }}
                collectionTitle={collection.name}
                collectionDescription={collection.description}
                context={'Single Collection'}
                onSearch={reloadAction}
              />
            </Dropdown.Item>
            {parseInt(collection.owner.id) ===
            parseInt(localStorage.getItem('user') as string) ? (
              <Dropdown.Item>
                <RemoveCollectionButton
                  //@ts-ignore
                  collectionId={collection.id}
                  style={{ width: '100%' }}
                  context={'Single Collection'}
                  onSearch={reloadAction}
                />
              </Dropdown.Item>
            ) : null}
          </Dropdown>
          <div className=" button-shared-style">
            <ManagePeopleCollectionButton
              //@ts-ignore
              collection={collection}
              collectionId={collection.id}
              sharedWith={collection.shared_with}
              context={'Single Collection'}
              onSearch={reloadAction}
              style={{ width: 'auto' }}
            />
          </div>
          <div className="button-export">
            <ExportReport handleClickExport={handleClickExport} />
          </div>
        </div>
      </div>

      <DrawerCollection
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        collectionId={collection.id}
        message={''}
      />
    </div>
  );
};

export default CollectionHeader;

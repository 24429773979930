import PropTypes from 'prop-types';
import './style.scss';
import { operators } from '../../../../../config/operadores';
import OperatorCard from './OperatorCard';
import { useState } from 'react';

const OperatorsHelperOpen = ({
  addOperator = () => {},
  hasSearchResults = false,
  hasSearchBarResults = false,
  top,
}) => {
  const [hoveredOperator, setHoveredOperator] = useState(null);

  const handleMouseEnter = (operator) => {
    setHoveredOperator(operator);
  };

  const handleMouseLeave = () => {
    setHoveredOperator(null);
  };

  const handleButtonClick = (operator) => {
    addOperator(operator.addOperator);
  };

  return (
    <div className="operators">
      <span>
        <strong>Operadores:</strong> são formas mais inteligentes de encontrar
        os melhores resultados
      </span>

      <div className="operators-buttons">
        {operators.map((operator, index) => (
          <div
            key={index}
            className="operator-wrapper"
            onMouseEnter={() => handleMouseEnter(operator)}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="operator-button"
              title={operator.description.props.children
                .map((child) =>
                  typeof child === 'string' ? child : child.props.children,
                )
                .join('')}
              onClick={() => handleButtonClick(operator)}
            >
              {operator.name}
            </button>

            {hoveredOperator?.name === operator.name && (
              <div
                className={`operator-tooltip ${top && !hasSearchBarResults ? 'in-results' : ''}`}
              >
                <OperatorCard
                  operator={hoveredOperator}
                  addOperator={(data) => console.log('Add Operator:', data)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

OperatorsHelperOpen.propTypes = {
  addOperator: PropTypes.func,
  hasSearchResults: PropTypes.bool,
  hasSearchBarResults: PropTypes.bool,
};

export default OperatorsHelperOpen;

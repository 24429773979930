
import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface DateCardProps {
  date: string;
}

const DateCardComponent: React.FC<DateCardProps> = ({ date }) => {
  if (!date || date === 'Invalid Date') {
    return null; 
  }

  const formattedDate = new Date(date);
  const monthAbbreviated = format(formattedDate, 'MMM', { locale: ptBR }).toUpperCase();
  const dayFormatted = format(formattedDate, 'dd');
  const yearFormatted = format(formattedDate, 'yyyy');

  return (
    <div className="inline-block text-center p-1 bg-blue-50 rounded-md mt-4 min-w-[54px] min-h-[66px]p-4">
      <div className="text-xs text-gray-600">{monthAbbreviated}</div>
      <div className="text-xl font-semibold text-gray-800">{dayFormatted}</div>
      <div className="text-[10px] text-gray-600">{yearFormatted}</div>
    </div>
  );
};

export default DateCardComponent;

import { ArrowRight } from 'iconsax-react';
import './style.scss';

const OperatorCard = ({ operator, addOperator }) => {
  return (
    <div className="operators-card">
      <div className="operators-card_header">
        <span className="font-semibold">Operador</span>
        <div
          className={`operators-btn-item operators-btn-color-${operator.operatorColor}`}
          data-testid={operator.dataTestId}
        >
          {operator.name}
        </div>
      </div>
      <div className="operators-card_content">{operator.description}</div>
      <div className="operators-card_footer">
        <a href={operator.videoRoute} target="_blank" rel="noreferrer">
          Veja o vídeo aqui
        </a>
        <ArrowRight size={16} color="#053AC8" />
      </div>
    </div>
  );
};

export default OperatorCard;

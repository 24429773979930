import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rsuite';
import './TuriviusButton.scss';
import 'rsuite/styles/index.less';

const TuriviusButton = ({
  children,
  appearance = 'default',
  onClick = () => {},
  disabled = false,
  fullWidth = false,
  loading = false,
  backgroundColor = '',
  color = '',
  style,
  className,
  size = 'md',
}) => (
  <Button
    className={className}
    appearance={appearance}
    onClick={onClick}
    disabled={disabled}
    block={fullWidth}
    loading={loading}
    size={size}
    style={{ ...style, backgroundColor, color, borderColor: color }}
  >
    {children}
  </Button>
);

TuriviusButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  appearance: PropTypes.oneOf([
    'default',
    'primary',
    'link',
    'subtle',
    'ghost',
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  className: PropTypes.string,
};

export default TuriviusButton;

import React, { useEffect, useState } from 'react';
import './HeaderFilter.scss';
import { Button, InputGroup, Input, toaster, Message, Loader } from 'rsuite';
import {
  AddCircle,
  CloseCircle,
  Danger,
  Edit2,
  FilterEdit,
  Trash,
} from 'iconsax-react';
import {
  createResult,
  deleteResult,
  getAllResults,
  getResultById,
  updateResult,
} from '../../FilterPath';
import { transformTesteToDataQuery } from './utils/transformTesteToDataQuery';
import { format, parse } from 'date-fns';

const HeaderFilter = ({
  CloseModal,
  isCarregarFiltros,
  filtersProps,
  setNewValue,
  handleStateUpdate,
  setTrigger,
}) => {
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [newFilterName, setNewFilterName] = useState('');
  const [editingFilterId, setEditingFilterId] = useState(null);
  const [editingFilterName, setEditingFilterName] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [filterToDelete, setFilterToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilterId, setSelectedFilterId] = useState(null);
  const currentDate = new Date();
  const creation_date = currentDate.toISOString();
  const [params, setParams] = useState({});
  const [valueTransform, setValueTransform] = useState({});

  const data_query = transformTesteToDataQuery(valueTransform);
  const [appliedFilterId, setAppliedFilterId] = useState(null);

  const handleDecodeParams = () => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);

    const paramsObject = {};

    queryParams.forEach((value, key) => {
      try {
        if (key === 'filters' || key === 'fields') {
          const decodedValue = decodeURIComponent(value);
          paramsObject[key] = JSON.parse(decodedValue);
        } else {
          paramsObject[key] = JSON.parse(value);
        }
      } catch (e) {
        paramsObject[key] = value;
      }
    });
    setParams(paramsObject);

    return paramsObject;
  };

  useEffect(() => {
    const params = handleDecodeParams();
    setParams(params);
  }, []);

  const formatCreationDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }) +
      ' ' +
      date.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  };

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const data = await getAllResults();
        if (Array.isArray(data)) {
          setFilters(data);
        }
      } catch (error) {
        console.error('Erro ao buscar os resultados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading2(true);
      try {
        const data = await getResultById(selectedFilterId);
        setValueTransform(data);
        localStorage.setItem(
          'filters',
          JSON.stringify({
            filter_by_secao_carf: data.filter_by_secao_carf,
            filter_by_vote: data.filter_by_vote,
            filter_by_relator: data.filter_by_relator,
            filter_by_favorability: data.filter_by_favorability,
            entities: data.entities,
            date_range: {
              0: format(data.start_date, 'dd/MM/yyyy'),
              1: format(data.end_date, 'dd/MM/yyyy'),
            },
          }),
        );
        filtersProps.setTmpFilters('entities', data.entities);
        localStorage.setItem('fields', JSON.stringify(data.where_search));
        const today = new Date();
        const formattedToday = format(today, 'yyyy-MM-dd');
        const lastYearStart = format(
          new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
          'yyyy-MM-dd',
        );
        const last2YearsStart = format(
          new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()),
          'yyyy-MM-dd',
        );
        const last5YearsStart = format(
          new Date(today.getFullYear() - 5, today.getMonth(), today.getDate()),
          'yyyy-MM-dd',
        );
        if (
          data.start_date === lastYearStart &&
          data.end_date === formattedToday
        ) {
          localStorage.setItem('whats_year', 'last_year');
        } else if (
          data.start_date === last2YearsStart &&
          data.end_date === formattedToday
        ) {
          localStorage.setItem('whats_year', 'last_2_years');
        } else if (
          data.start_date === last5YearsStart &&
          data.end_date === formattedToday
        ) {
          localStorage.setItem('whats_year', 'last_5_years');
        } else {
          localStorage.setItem('whats_year', 'any_date');
        }
      } catch (error) {
        setLoading2(false);
        console.error('Erro ao buscar os resultados:', error);
      } finally {
        setLoading2(false);
      }
    };

    fetchResults();
  }, [selectedFilterId]);

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('modal-overlay')) {
      CloseModal();
    }
  };

  const handleCreateFilter = async () => {
    setTrigger();
    let filtersLocal = localStorage.getItem('filters');
    const userId = +localStorage.getItem('user');
    filtersLocal = JSON.parse(filtersLocal);

    if (newFilterName.trim() === '') {
      toaster.push(
        <Message showIcon type="error" duration={5000}>
          Por favor, insira um nome para o novo filtro.
        </Message>,
        { placement: 'topEnd' },
      );
      return;
    }

    const startParsedDate = filtersLocal?.date_range[0]?.includes('/')
      ? parse(filtersLocal?.date_range[0], 'dd/MM/yyyy', new Date())
      : parse(filtersLocal?.date_range[0], 'yyyy/MM/dd', new Date());

    const endParsedDate = filtersLocal?.date_range[1]?.includes('/')
      ? parse(filtersLocal?.date_range[1], 'dd/MM/yyyy', new Date())
      : parse(filtersLocal?.date_range[1], 'yyyy/MM/dd', new Date());

    const newFilter = {
      filter_by_vote: filtersLocal?.filter_by_vote || [],
      user_id: userId,
      name: newFilterName,
      description: '',
      creation_date: creation_date,
      rr: true,
      rg: false,
      where_search: params?.fields,
      similar_terms: true,
      start_date: format(startParsedDate, 'yyyy-MM-dd'),
      end_date: format(endParsedDate, 'yyyy-MM-dd'),
      is_delete: false,
      filter_by_relator: filtersLocal?.filter_by_relator || [],
      filter_by_secao_carf: filtersLocal.filter_by_secao_carf || [],
      filter_by_favorability: filtersLocal.filter_by_favorability || [],
      entities: filtersLocal?.entities,
    };

    try {
      await createResult(newFilter);
      const updatedResults = await getAllResults();
      setFilters(updatedResults);
      setNewFilterName('');

      const toasterId = await toaster.push(
        <div className="custom-toaster">
          <div className="toaster-icon">
            <FilterEdit
              size="24"
              color="#12B76A"
              style={{ marginRight: '8px' }}
            />
          </div>
          <span>
            Novo modelo de filtro salvo
            <br />
            com sucesso
          </span>
          <Button
            appearance="link"
            className="toaster-close-button"
            onClick={() => toaster.remove(toasterId)}
          >
            <CloseCircle size="24" color="#667085" />
          </Button>
        </div>,
        { placement: 'topEnd' },
      );

      setTimeout(() => {
        toaster.remove(toasterId);
      }, 5000);
      CloseModal();
    } catch (error) {
      const errorMessage = error?.message || 'Erro ao criar o filtro';
      const toasterId = await toaster.push(
        <div className="custom-toaster">
          <div className="toaster-icon">
            <FilterEdit
              size="24"
              color="#ff0000"
              style={{ marginRight: '8px' }}
            />
          </div>
          <span>Por favor selecione os filtros desejados: {errorMessage}</span>
          <Button
            appearance="link"
            className="toaster-close-button"
            onClick={() => toaster.remove(toasterId)}
          >
            <CloseCircle size="24" color="#667085" />
          </Button>
        </div>,
        { placement: 'topEnd' },
      );

      setTimeout(() => {
        toaster.remove(toasterId);
      }, 5000);
    }
  };

  const handleEditFilterClick = (filter) => {
    setEditingFilterId(filter.uuid_filter);
    setEditingFilterName(filter.name);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setShowConfirmationModal(true);
    }
  };

  const handleSaveEditFilter = async () => {
    const previousFilters = [...filters];
    const updatedFilters = filters.map((filter) =>
      filter.uuid_filter === editingFilterId
        ? { ...filter, name: editingFilterName }
        : filter,
    );

    setFilters(updatedFilters);
    setEditingFilterId(null);
    setEditingFilterName('');
    setShowConfirmationModal(false);

    try {
      await updateResult(editingFilterId, { name: editingFilterName });

      const toasterId = await toaster.push(
        <div className="custom-toaster">
          <div className="toaster-icon">
            <FilterEdit
              size="24"
              color="#12B76A"
              style={{ marginRight: '8px' }}
            />
          </div>
          <span>
            Filtro reescrito com <br />
            sucesso
          </span>
          <Button
            appearance="link"
            className="toaster-close-button"
            onClick={() => {
              toaster.remove(toasterId);
              setFilters(previousFilters);
            }}
          >
            desfazer
          </Button>
        </div>,
        { placement: 'topEnd' },
      );

      setTimeout(() => {
        toaster.remove(toasterId);
      }, 5000);
    } catch (error) {
      console.error('Erro ao salvar a edição do filtro:', error);
      toaster.push(
        <Message showIcon type="error" duration={5000}>
          Erro ao salvar a edição do filtro. Tente novamente.
        </Message>,
        { placement: 'topEnd' },
      );

      setFilters(previousFilters);
    }
  };

  const handleCancelEdit = () => {
    setShowConfirmationModal(false);
    setEditingFilterId(null);
    setEditingFilterName('');
  };

  const handleDeleteFilterClick = (uuid_filter) => {
    setFilterToDelete(uuid_filter);
    setShowDeleteConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!filterToDelete) return;

    let deleteCancelled = false;

    const toasterId = await toaster.push(
      <div className="custom-toaster">
        <div className="toaster-icon">
          <FilterEdit
            size="24"
            color="#12B76A"
            style={{ marginRight: '8px' }}
          />
        </div>
        <span>Filtro deletado com sucesso</span>
        <Button
          appearance="link"
          className="toaster-close-button"
          onClick={() => {
            deleteCancelled = true;
            toaster.remove(toasterId);
            setShowDeleteConfirmationModal(false);
          }}
        >
          desfazer
        </Button>
      </div>,
      { placement: 'topEnd' },
    );

    setTimeout(async () => {
      if (!deleteCancelled) {
        try {
          await deleteResult(filterToDelete);

          const updatedFilters = filters.filter(
            (filter) => filter.uuid_filter !== filterToDelete,
          );
          setFilters(updatedFilters);
          toaster.remove(toasterId);
        } catch (error) {
          console.error(
            `Erro ao deletar o filtro com ID ${filterToDelete}:`,
            error,
          );
        } finally {
          setFilterToDelete(null);
          setShowDeleteConfirmationModal(false);
        }
      }
    }, 2500);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmationModal(false);
    setFilterToDelete(null);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleChooseFilterClick = (uuid_filter) => {
    setSelectedFilterId(uuid_filter);

    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
  };

  const filteredFilters = filters.filter((filter) =>
    filter.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleApplyFilter = async () => {
    try {
      const idToApply = selectedFilterId;
      setAppliedFilterId(idToApply);
      filtersProps.saveFilters(
        filtersProps.history,
        filtersProps.match,
        encodeURIComponent(filtersProps.query),
      );
      handleStateUpdate({ appliedFilterId: idToApply });
      CloseModal();
    } catch (error) {
      console.error('Erro ao aplicar o filtro:', error);
      toaster.push(
        <Message showIcon type="error" duration={5000}>
          Erro ao aplicar o filtro. Tente novamente.
        </Message>,
        { placement: 'topEnd' },
      );
    }
  };

  useEffect(() => {
    setNewValue(data_query);
    handleStateUpdate(appliedFilterId);
  }, [selectedFilterId, valueTransform]);

  return (
    <div>
      {!showConfirmationModal && !showDeleteConfirmationModal && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-filter">
            <div className="modal-header-filter">
              <h3
                style={{
                  fontSize: '1.25rem',
                  color: '#0a58ca',
                  fontWeight: '700',
                }}
              >
                {isCarregarFiltros ? 'Carregar meus filtros' : 'Salvar filtro'}
              </h3>
              <Button
                appearance="subtle"
                className="close-button"
                onClick={CloseModal}
              >
                <CloseCircle size="24" color="#98A2B3" />
              </Button>
            </div>
            <p
              style={{
                fontSize: '1rem',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#666',
                marginBottom: '20px',
                textAlign: 'initial',
              }}
            >
              {isCarregarFiltros
                ? 'Carregue seus modelos de filtros mais usados e agilize sua pesquisa'
                : 'Salve seus modelos de filtros mais usados e agilize sua pesquisa'}
            </p>
            <div className="filter-name-input">
              {!isCarregarFiltros ? (
                <InputGroup inside>
                  <InputGroup.Addon>
                    <AddCircle size="12" color="#98A2B3" />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Escolha o nome do seu novo filtro"
                    value={newFilterName}
                    onChange={(value) => setNewFilterName(value)}
                  />
                </InputGroup>
              ) : (
                <InputGroup inside>
                  <InputGroup.Addon>
                    <AddCircle size="12" color="#98A2B3" />
                  </InputGroup.Addon>
                  <Input
                    placeholder="Procure pelo nome do filtro já salvo"
                    value={searchTerm}
                    onChange={(value) => handleSearchChange(value)}
                  />
                </InputGroup>
              )}
            </div>
            <div className="saved-filters">
              <p
                style={{
                  fontSize: '0.875rem',
                  color: '#000000',
                  fontWeight: '500',
                  marginBottom: '10px',
                  fontFamily: 'Poppins',
                  textAlign: 'left',
                }}
              >
                Meus filtros salvos
              </p>

              <ul>
                {loading ? (
                  <ul style={{ padding: '40px' }}>
                    <li
                      key="loading"
                      style={{
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      <Loader
                        style={{
                          paddingTop: '140px',
                        }}
                        content="Carregando filtros..."
                        center
                        vertical
                        size="md"
                      />
                    </li>
                  </ul>
                ) : filters?.length === 0 ? (
                  <li
                    key="no-filters"
                    style={{
                      color: '#D0D5DD',
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                  >
                    Você não tem filtros salvos
                  </li>
                ) : (
                  filteredFilters?.map((filter) => (
                    <li key={filter.uuid_filter}>
                      <div className="filter-info">
                        {editingFilterId === filter.uuid_filter ? (
                          <div>
                            <Input
                              value={editingFilterName}
                              onChange={(value) => setEditingFilterName(value)}
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                        ) : (
                          <div className="filter-item">
                            <div className="filter-details">
                              <h2
                                className={
                                  selectedFilterId === filter.uuid_filter
                                    ? 'active-filter'
                                    : ''
                                }
                              >
                                {filter.name}
                              </h2>
                              <Button
                                appearance="link"
                                onClick={() => handleEditFilterClick(filter)}
                              >
                                <Edit2 size="12" color="#D0D5DD" />
                              </Button>
                              <span>
                                Criado em{' '}
                                {formatCreationDate(filter?.creation_date)}
                              </span>
                            </div>
                            <div className="filter-actions">
                              <Button
                                appearance="link"
                                onClick={() =>
                                  handleDeleteFilterClick(filter.uuid_filter)
                                }
                              >
                                <div className="trash-icon">
                                  <Trash size="10" color="#F04438" />
                                </div>
                              </Button>

                              <Button
                                style={{ textDecoration: 'none' }}
                                className={`filter-actions ${
                                  selectedFilterId === filter.uuid_filter
                                    ? 'selected'
                                    : ''
                                }`}
                                appearance="link"
                                onClick={() =>
                                  handleChooseFilterClick(filter.uuid_filter)
                                }
                              >
                                {selectedFilterId === filter.uuid_filter ? (
                                  <span>Selecionado</span>
                                ) : (
                                  <span>Escolher</span>
                                )}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div className="modal-footer">
              {isCarregarFiltros ? (
                <>
                  {console.log(loading, 'loading')}
                  <Button
                    disabled={loading2}
                    className="button-apply-filter"
                    onClick={() => handleApplyFilter()}
                  >
                    Carregar e aplicar filtro
                  </Button>
                  <Button className="cancel-button" onClick={CloseModal}>
                    Cancelar
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="button-save-filter"
                    onClick={handleCreateFilter}
                  >
                    Salvar novo modelo de filtro
                  </Button>
                  <Button className="cancel-button" onClick={CloseModal}>
                    Cancelar
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showConfirmationModal && (
        <div className="custom-modal-overlay" onClick={handleCancelEdit}>
          <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
            <div className="button-close-submodal">
              <Button className="close-submodal" onClick={CloseModal}>
                <CloseCircle size="16" color="#98A2B3" />
              </Button>
            </div>
            <div className="custom-modal-header">
              <Danger size="24" color="#FF8A65" />
              <h3
                style={{
                  fontSize: '14px',
                  marginLeft: '10px',
                  color: '#101828',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                  textAlign: 'initial',
                  lineHeight: '20px',
                }}
              >
                Deseja mesmo reescrever <br />
                esse filtro?
              </h3>
              <button
                className="close-button"
                onClick={handleCancelEdit}
              ></button>
            </div>
            <div className="custom-modal-footer">
              <button
                className="modal-button subtle"
                onClick={handleCancelEdit}
              >
                Cancelar
              </button>
              <button
                className="modal-button primary"
                onClick={handleSaveEditFilter}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteConfirmationModal && (
        <div className="custom-modal-overlay" onClick={handleCancelDelete}>
          <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
            <div className="button-close-submodal">
              <Button className="close-submodal" onClick={CloseModal}>
                <CloseCircle size="16" color="#98A2B3" />
              </Button>
            </div>
            <div className="custom-modal-header">
              <Danger size="24" color="#FF8A65" />
              <h3
                style={{
                  fontSize: '14px',
                  marginLeft: '10px',
                  color: '#101828',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                }}
              >
                Deseja mesmo deletar esse filtro?
              </h3>
            </div>
            <div className="custom-modal-footer">
              <button
                className="modal-button subtle"
                onClick={handleCancelDelete}
              >
                Cancelar
              </button>
              <button
                className="modal-button primary"
                onClick={handleConfirmDelete}
              >
                Deletar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderFilter;

import { useEffect, useState } from 'react';
import SearchCollection from '../Collections/SearchCollection';
import CollectionCenterCard from '../Collections/CollectionCenterCard';
import CollectionOrderBy from '../Collections/CollectionOrderBy';

const SharedWithMeSession = ({ getCollections }) => {
  const group = 'shared_with';
  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [haveCollections, setHaveCollections] = useState(false);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-last_update');

  function onSearch(value = '', page = 1) {
    setLoading(true);
    setPage(page);
    getCollections(
      group,
      value,
      page,
      ordering,
      (data) => {
        setCollections(data);
        setLoading(false);
      },
      setLoading,
      setHaveCollections,
    );
  }

  function onChangePage(page) {
    setPage(page);
    getCollections(
      group,
      filter,
      page,
      ordering,
      setCollections,
      setLoading,
      setHaveCollections,
    );
  }

  function onCleanFilter() {
    setFilter('');
    getCollections(
      group,
      '',
      page,
      ordering,
      setCollections,
      setLoading,
      setHaveCollections,
    );
  }

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <div style={{ padding: ' 0 24px 24px 24px' }}>
      <SearchCollection
        noCreate
        title="Coleções Compartilhadas Comigo"
        setFilter={setFilter}
        onSearch={onSearch}
        filter={filter}
        onChangeFilter={setFilter}
        id="shared-collections"
      />

      <CollectionOrderBy
        ordering={ordering}
        setOrdering={setOrdering}
        onOrdering={onSearch}
        collectionCount={collections.results?.length}
      />

      <CollectionCenterCard
        shared
        loading={loading}
        collections={collections}
        page={page}
        filter={filter}
        onCleanFilter={onCleanFilter}
        onChangePage={onChangePage}
      />
    </div>
  );
};

export default SharedWithMeSession;

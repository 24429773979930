import React from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination } from 'rsuite';
import './TuriviusTable.scss';

const CustomCell = ({ rowData, dataKey, customCell, ...props }) => (
  <Table.Cell {...props}>
    {customCell({ rowData, dataKey, customCell, ...props })}
  </Table.Cell>
);

const TuriviusTable = ({
  data = [],
  columns = [],
  onRowClick = () => {},
  locale = {
    emptyMessage: 'Nenhum dado encontrado',
    loading: 'Carregando...',
  },
  paginate = false,
  first = true,
  last = true,
  next = true,
  prev = true,
  defaultActivePage = 1,
  maxButtons = 5,
  total = 100,
  limit = 10,
  onChangePage = () => {},

  autoHeight,
  bordered,
  cellBordered,
  hover,
  loading,
  minHeight,
  rowClassName,
  rowHeight,
  showHeader,
  width,
  height,
  wordWrap,
  style,
  paginationStyle,
  activePage,
}) => {
  const [defaultActivePages, setDefaultActivePages] = React.useState(
    defaultActivePage || 1,
  );
  return (
    <div>
      <Table
        autoHeight={autoHeight}
        bordered={bordered}
        cellBordered={cellBordered}
        columns={columns}
        data={data}
        hover={hover}
        loading={loading}
        locale={locale}
        minHeight={minHeight}
        onRowClick={onRowClick}
        rowClassName={rowClassName}
        rowHeight={rowHeight}
        showHeader={showHeader}
        width={width}
        height={height}
        wordWrap={wordWrap}
        style={style}
      >
        {columns?.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Table.Column {...rest} key={key}>
              {column.customHeader ? (
                <column.customHeader>{label}</column.customHeader>
              ) : (
                <Table.HeaderCell>{label}</Table.HeaderCell>
              )}
              {column.customCell ? (
                <CustomCell dataKey={key} customCell={column.customCell} />
              ) : (
                <Table.Cell dataKey={key} />
              )}
            </Table.Column>
          );
        })}
      </Table>
      {paginate ? (
        <Pagination
          total={total}
          limit={limit}
          first={first}
          last={last}
          next={next}
          prev={prev}
          maxButtons={maxButtons}
          activePage={activePage || defaultActivePages}
          onChangePage={(page) => {
            setDefaultActivePages(page);
            onChangePage(page);
          }}
          style={paginationStyle || {}}
        />
      ) : null}
    </div>
  );
};

TuriviusTable.propTypes = {
  autoHeight: PropTypes.bool,
  bordered: PropTypes.bool,
  cellBordered: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  hover: PropTypes.bool,
  loading: PropTypes.bool,
  locale: PropTypes.shape({
    emptyMessage: PropTypes.string,
    loading: PropTypes.string,
  }),
  minHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.number,
  showHeader: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  wordWrap: PropTypes.bool,
  style: PropTypes.object,

  paginate: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  next: PropTypes.bool,
  prev: PropTypes.bool,
  defaultActivePages: PropTypes.number,
  maxButtons: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
  onChangePage: PropTypes.func,
  paginationStyle: PropTypes.object,
};

export default TuriviusTable;

import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from '../../../../../../components/ui/dialog';
import { useLocation } from 'react-router-dom';

const NOTIFICATION_KEY = 'modal-grande-resumo-inteiro-teor-v2';

export const BigLaunchMode = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(NOTIFICATION_KEY, 'false');
  };

  useEffect(() => {
    const _show = JSON.parse(localStorage.getItem(NOTIFICATION_KEY) as string);

    if (_show === null) {
      setOpen(true);
    } else {
      setOpen(_show);
    }
  }, [pathname]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogPortal>
        <DialogOverlay className="bg-black/30" />
        <DialogContent className="max-w-[891px] flex gap-0 p-0 z-[100] rounded-lg overflow-hidden">
          <div className="flex flex-col max-w-[298px] bg-[#053AC8] text-white font-poppins text-sm py-20 px-14">
            <span className="mb-2.5">NOVIDADE</span>
            <span className="text-xl font-bold leading-6 w-48 mb-6">
              Bem-vindo(a) à Nova Versão da Turivius! 
            </span>
            <span className="w-[192px]">
              Estamos lançando uma versão ainda mais 
              intuitiva e poderosa para transformar sua 
              experiência jurídica. <br></br>
              <br></br>
              E aguarde: nas próximas semanas teremos ainda mais novidades!
            </span>
          </div>
          <div className="flex flex-col bg-white pt-[71px] px-14">
            <span className="w-fit text-xl text-[#053AC8] leading-5 font-medium mb-[34px]">
              {' '}
              Novas Funcionalidades
            </span>
            <div className="gap-4 flex flex-col w-[491px] leading-6">
              <span className="max-w-[470px]">
                <strong>Açõe em Lote:</strong> Selecione várias decisões de uma só vez e salve-as em coleções ou exporte as citações.
              </span>
              <span>
                <strong>Nova barra de filtros:</strong> Mais intuitiva e fácil de usar. E você pode remover ou salvar seu filtros em poucos cliques.
              </span>
              <span>
                <strong>Repercussão Geral e Recurso Repetitivo:</strong> Filtre precedentes importantes das Cortes Superiores.
              </span>
              <span>
                <strong>Marcadores em massa:</strong> Nas coleções, selecione várias decisões de uma só vez e adicione tags do resultado para gerar relatório.
              </span>
              <span>
                <strong>Busca por números de processos:</strong>  Encontre decisões com facilidade pelo número do processo.
              </span> 
              {/* <span className="max-w-[470px]">
                <strong>Repercussão Geral e Recurso Repetitivo:</strong> Agora você pode filtrar precedentes importantes por tipo.
              </span> */}
             
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

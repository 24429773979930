import { Col, Grid, Pagination, Row } from 'rsuite';
import CollectionFolder from '../CollectionFolder/CollectionFolder';
import CollectionWithoutResults from '../CollectionWithoutResults';
import NoCollection from '../NoCollection';
import TuriviusPreloader from '../../../../../app/TuriviusPreloader';

const CollectionCenterCard = ({
  onSearch,
  onChangePage,
  onCleanFilter,
  loading,
  collections,
  page,
  filter,
  shared,
}) => {
  if (loading) {
    return (
      <Col xs={24} sm={24} style={{ height: 150 }}>
        <TuriviusPreloader />
      </Col>
    );
  }

  // Se essa verificação ficar primeiro ela impede que o loading carregue
  if (!collections?.results?.length) return null;

  const filteredCollections = collections.results.filter((collection) =>
    collection.name.toLowerCase().includes(filter.toLowerCase()),
  );

  return (
    <Grid fluid>
      <Row>
        {filteredCollections.length > 0 ? (
          <>
            {filter && (
              <p
                className="information-collection-search"
                style={{ marginTop: '10px' }}
              >
                {filteredCollections.length} resultado(s) encontrado(s) para a
                pesquisa <b>{filter}</b>
              </p>
            )}
            {filteredCollections.map((collection, index) =>
              index === 0 ? (
                <CollectionFolder
                  key={collection.id}
                  id={'collection-folder-selected'}
                  shared={shared}
                  onSearch={onSearch}
                  collection={collection}
                  page={page}
                  loader={'userCollectionsLoading'}
                />
              ) : (
                <CollectionFolder
                  key={collection.id}
                  id="collection-folder"
                  shared={shared}
                  onSearch={onSearch}
                  collection={collection}
                  page={page}
                  loader={'userCollectionsLoading'}
                />
              ),
            )}
          </>
        ) : collections?.results?.length === 0 && filter ? (
          <CollectionWithoutResults
            onCleanFilter={onCleanFilter}
            filter={filter}
          />
        ) : (
          <NoCollection getCollection={onSearch} />
        )}
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Pagination
          prev
          last
          next
          first
          className="collections-paginator"
          total={collections.count}
          limit={12}
          maxButtons={5}
          activePage={page}
          onChangePage={(page) => onChangePage(page)}
        />
      </Row>
    </Grid>
  );
};
export default CollectionCenterCard;

import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowCircleDown2, Eye, Login, TrendUp } from 'iconsax-react';
import { toaster, Message, Whisper, Tooltip } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { posthog, requester } from '../../../../requesters';
import { Nav, Button } from '../../../../ds-components';
import SearchBarResults from '../../components/search/SearchBar/SearchBarResults';
import FiltersList from '../../components/results/FilterList/FilterList';
import ResultCardsContainer from '../../components/results/Cards/Result/ResultCardsContainer';
import ResultContainerLoader from '../../components/results/Cards/Result/ResultContainerLoader';
import OrderResults from '../../components/results/FilterList/Filters/OrderResults';
import SLAJurimetric from '../../../../components/organisms/ModalSLAJurimetric/SLAJurimetric';
import { updateCheckllist } from '../../../../services/checklist';
import NoResultsMessage from '../../components/results/NoResultsMessage';
import ToggleMonitoring from '../../../monitoring/components/Buttons/ToggleMonitoring';
import { useLocation } from 'react-router-dom';
import JurimetricsCenter from '../../components/results/SearchJurimetrics/JurimetricsCenter';
import { useCollections } from '../../../../store/ducks/theCollections';
import BulkActionBar from '../../components/results/BulkActionBar/BulkActionBar';
import { Types as BulkactionTypes } from '../../../../store/ducks/collectionsBulkaction';
import { clearFilter } from '../../components/results/FilterList/Filters/NewFilterList/components/HeaderFilter/utils/transformTesteToDataQuery';
import './Results.css';

const Results = (props) => {
  const [query, setQuery] = useState('');
  const [dataQuery, setDataQuery] = useState(null);
  const [requesting, setRequesting] = useState(true);
  useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [results, setResults] = useState([]);
  const [resultsTime, setResultsTime] = useState(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState({});
  const [requestingSearchJurimetrics, setRequestingSearchJurimetrics] =
    useState(false);
  const [searchPlots, setSearchPlots] = useState([]);
  const [entitiesMonitoring, setEntitiesMonitoring] = useState([]);
  const [noJurimetricEntities, setNoJurimetricEntities] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [bases, setBases] = useState(null);
  const [classified, setClassified] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [loadingJurimetria, setLoadingJurimetria] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isMob, setIsMob] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const [valitadionFilter, setValitadionFilter] = useState(false);
  const [clearFiltro, setClearFiltro] = useState(false);
  const [salueStyles, setValueStyles] = useState(false);
  const [colapse, setColapse] = useState(true);
  const [isHome, setIsHome] = useState(true);
  const [isSearching, setIsSearching] = useState(true);

  // -----------------------------------------------------------
  // Controle de escurecimento
  const [isFocused, setIsFocused] = useState(false);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBarRef]);

  // -----------------------------------------------------------

  const isFirstLoad = useRef(true);

  const toggleFilterList = () => {
    setColapse(!colapse);
  };

  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);

  const handleScroll = () => {
    const AisScrolled = window.scrollY > 1;
    if (isScrolled !== AisScrolled) {
      setIsScrolled(AisScrolled);
    }
  };

  const { collections: theCollections, fetch: fetchCollections } =
    useCollections();
  const dispatch = useDispatch();
  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const location = useLocation();

  const verifyAndGetEntities = async () => {
    let { entities } = props;

    if (Object.keys(entities).length === 0 && entities.constructor === Object) {
      await requester.cards
        .getAllEntities()
        .then((response) => {
          entities = {};
          response.forEach((entity) => {
            entities[entity.id] = entity;
          });
          props.setEntities(entities);
        })
        .catch((err) => {
          console.error(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente
            </Message>,
          );
        });
    }
  };

  const searchPlotsFunc = async (data_query) => {
    // FIXME: o filters.entity deveria ser tratado
    let filters = JSON.parse(data_query.filters);
    if (filters?.entity?.length > 0 || filters?.entities?.length > 0) {
      try {
        if (filters?.entities) {
          filters = {
            ...filters,
            entity: JSON.parse(data_query?.filters).entities,
          };
        }

        delete filters.entities;
      } catch {
        console.log('that fer: ', filters);
      }

      setRequestingSearchJurimetrics(true);
      setTimeout(() => {
        props
          .getPlots(data_query, filters)
          .then((response) => {
            setSearchPlots(response);
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setRequestingSearchJurimetrics(false);
          });
      }, 500);
    } else {
      setNoJurimetricEntities(true);
    }
  };

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolledDown(true);
      } else {
        setScrolledDown(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getResults = async (
    count,
    query,
    filterStr,
    inteiro_teor,
    default_operator,
    synonyms,
    n_acordao,
    n_processo,
    relator_ids,
    full_text,
    searchMode,
  ) => {
    // TODO: Essa função faz um wrapper para busca
    setRequesting(true);
    props.setEntitiesCounter({});
    props.setGeneralCounter({});
    props.setExternalFilter('filter_by_relator', []);

    let filtersFiltered = {};
    filtersFiltered = JSON.parse(filterStr);

    if (
      n_acordao ||
      n_processo ||
      (relator_ids && relator_ids.length > 0) ||
      full_text
    ) {
      const data = {
        query: '',
        page_n: page,
        filters: JSON.stringify(filtersFiltered),
        n_acordao,
        n_processo,
        relator: relator_ids,
        full_text,
        search_mode: searchMode,
      };

      let data_query = {};
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined) {
          data_query[key] = data[key];
        }
      });

      if (count) {
        props.getCountResults(data_query);
      }

      setData({ ...data_query });

      let startTime = new Date();

      props
        .search(data_query)
        .then((response) => {
          setResults(response.cards);
          setResultsTime(((new Date() - startTime) / 1000).toFixed(2));

          props.getVotes(response.cards?.map((c) => c.id));
          if (response.cards.length < 10) {
            setHasMore(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setResults([]);
        })
        .finally(() => {
          setRequesting(false);
        });
    } else if (query && query.length >= 3) {
      if (!filterStr || filterStr === 'null') {
        props.loadFilters();
        props.saveFilters(this.props.history, this.props.match);
      } else {
        //TODO: justSaveFitler (oq realmente faz)?
        props.justSaveFilters(JSON.stringify(filtersFiltered));

        const data_query = {
          query,
          page_n: page,
          fields: inteiro_teor,
          filters: JSON.stringify(filtersFiltered),
          default_operator,
          synonyms,
          search_mode: searchMode,
        };

        setDataQuery(
          clearFiltro
            ? clearFilter()
            : valitadionFilter
              ? newValue
              : data_query,
        );

        if (count) {
          props.getCountResults(
            clearFiltro
              ? clearFilter()
              : valitadionFilter
                ? newValue
                : data_query,
          );
        }

        setData({
          ...(clearFiltro
            ? clearFilter()
            : valitadionFilter
              ? newValue
              : data_query),
        });

        let startTime = new Date();
        const relator_filter = [];

        props
          .search(
            clearFiltro
              ? clearFilter()
              : valitadionFilter
                ? newValue
                : data_query,
          )
          .then(async (response) => {
            setResultsTime(((new Date() - startTime) / 1000).toFixed(2));

            posthog.capture('result_time', {
              result_time: ((new Date() - startTime) / 1000).toFixed(2),
            });

            if (response.cards.length < 10) {
              setHasMore(false);
            }

            props.getVotes(response.cards?.map((c) => c.id));
            setResults(response.cards);

            Object.keys(response.relatores)?.map((a, b) =>
              relator_filter.push({
                key: a,
                doc_count: response.relatores[a].doc_count,
                related_names: response.relatores[a].related_names.join(','),
              }),
            );
            setClearFiltro(false);
            setValitadionFilter(false);
            await props.setExternalFilter('filter_by_relator', relator_filter);
          })
          .catch((err) => {
            console.error(err);
            setResults([]);
          })
          .finally(() => setRequesting(false));
      }
    } else {
      props.setEntitiesCounter({});
      props.setGeneralCounter({});
      props.setExternalFilter('filter_by_relator', []);
      setRequesting(false);
      setResults([]);
    }
  };

  const getLoadMoreResults = (data_query) => {
    setLoadingMore(true);

    if (!requesting) {
      props
        .loadMoreResults(data_query)
        .then((response) => {
          if (response.cards.length < 10) {
            setHasMore(false);
          }
          setResults([...results, ...response.cards]);
          setPage(data_query.page_n);
          setLoadingMore(false);
          props.getVotes(response.cards?.map((c) => c.id));
        })
        .catch((err) => {
          console.error(err);
          setTimeout(() => {
            getLoadMoreResults(data_query);
          }, 1000);
        });
    }
  };

  const loadMore = () => {
    let data_query = data;
    data_query.page_n = page + 1;
    getLoadMoreResults(data_query);
  };

  const doSearch = async (location) => {
    // TODO: Essa é a função que realmente faz a busca
    // TODO: verifica se tem params
    if (location.search !== '') {
      const params = new URLSearchParams(location.search);
      const query = params.get('query');
      const filters = params.get('filters');
      const inteiro_teor = params.get('fields');
      const default_operator = params.get('default_operator');
      const synonyms = params.get('synonyms');
      const n_acordao = params.get('n_acordao');
      const n_processo = params.get('n_processo');
      const relator_ids = params.getAll('relator');
      const full_text = params.get('full_text');
      const searchMode = params.get('search_mode');

      setQuery(query);
      setHasMore(true);
      setPage(0);
      setSearchPlots([]);

      props.setQuery(query);
      props.setFilterStr(filters);
      if (!n_acordao && !n_processo && !relator_ids) {
        props.justSaveFilters(filters);
      }
      props.changeInteiroTeor(
        inteiro_teor !== 'null' ? inteiro_teor : ['ementa'],
      );
      props.changeDefaultOperator(
        default_operator !== 'null' && default_operator !== null
          ? default_operator
          : 'ou',
      );
      props.changeSynonyms(
        synonyms !== 'null' && synonyms !== null
          ? synonyms === 'true'
            ? true
            : false
          : false,
      );

      // filtersRedux.changeSearchMode(
      //   searchMode !== 'null' && searchMode !== null
      //     ? searchMode === 'normal'
      //       ? 'normal'
      //       : 'nproc'
      //     : 'normal',
      // );

      getResults(
        true,
        encodeURIComponent(query),
        filters,
        inteiro_teor !== 'null' ? inteiro_teor : ['ementa'],
        default_operator !== 'null' && default_operator !== null
          ? default_operator
          : 'ou',
        synonyms !== 'null' && synonyms !== null
          ? synonyms === 'true'
            ? true
            : false
          : false,
        n_acordao,
        n_processo,
        relator_ids,
        full_text,
        searchMode,
      );
    }
  };

  const handleChangeNavigation = (index) => {
    setActiveIndex(index);
  };

  const getEntitiesToMonitoring = () => {
    requester.cards
      .getEntities()
      .then((res) => {
        setEntitiesMonitoring(res);
      })
      .catch((err) => {
        setEntitiesMonitoring([]);
      });
  };

  const handleResize = () => {
    const isMob2 = window.detectMob();
    if (isMob2 !== isMob) {
      setIsMob(isMob2);
    }
  };

  const fetchData = async () => {
    getBasesAndClassifedAndAcurracy();
  };

  const getBasesAndClassifedAndAcurracy = async () => {
    try {
      setLoadingJurimetria(true);
      const basesRes = await requester.slaJurimetric.getSLABases();
      const classifiedRes = await requester.slaJurimetric.getSLAClassified();
      const acurracyRes = await requester.slaJurimetric.getSLAAccuracy();
      setBases(basesRes);
      setClassified(classifiedRes);
      setAccuracy(acurracyRes);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!results.length) return;
    const fetchCollections = async () => {
      const resultIds = results.map((result) => result.id);
      try {
        const response =
          await requester.collections.matchResultsThatHaveCollections(
            encodeURIComponent(JSON.stringify(resultIds)),
          );
        const theResponse = [...new Set(response)];
        setTheMatchResultsThatHaveCollections(theResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCollections();
  }, [theCollections, results]);

  useEffect(() => {
    doSearch(location);
  }, [location]);

  useEffect(() => {
    posthog.capture('$pageview');
    verifyAndGetEntities();
    getEntitiesToMonitoring();
    setIsMob(window.detectMob());
    window.addEventListener('resize', handleResize);

    fetchData();
    fetchCollections();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolledDown(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  useEffect(() => {
    requesting;
  }, [requesting]);

  useEffect(() => {
    newValue;
  }, [newValue]);

  const [storageData, setStorageData] = useState(() => {
    return JSON.parse(localStorage.getItem('menu_open')) || {};
  });
  useEffect(() => {
    setInterval(() => {
      const currentData = JSON.parse(localStorage.getItem('menu_open'));
      if (currentData !== storageData) {
        setStorageData(currentData);
      }
    }, 500);
  }, [storageData]);

  useEffect(() => {
    return () => {
      dispatch({ type: BulkactionTypes.UNSELECT_ALL });
    };
  }, []);
  // className={`transition duration-300 ease-out ${selectedCards.length > 0 ? 'translate-y-0' : '-translate-y-20'}`}
  return (
    <div className="resultpage">
      <div
        className="resultpage-header-search"
        style={{
          position: 'relative',
          top: '0',
          transition: 'all 0.2s ease-in-out',
          marginTop: selectedCards.length > 0 ? '60px' : '0',
        }}
      >
        {selectedCards.length > 0 && (
          <div
            className="bulk-action-bar"
            style={{
              position: 'fixed',
              top: '0',
              right: '0',
              zIndex: 100,
              transform: 'translateY(-100%)',
              animation: 'slideDown 0.2s ease-in-out forwards',
            }}
          >
            <BulkActionBar
              query={query}
              theCollections={theCollections}
              theMatchResultsThatHaveCollections={
                theMatchResultsThatHaveCollections
              }
              showExportCardsAsCSV={true}
              showAddCardToReadLaterButton={true}
              showCiteButton={true}
              showSaveCardToCollectionButton={true}
            />
          </div>
        )}
        <div
          className="menu menu-responsive"
          style={{
            position: 'fixed',
            zIndex: scrolledDown ? 100 : 0,
            zIndex: salueStyles ? '0' : '10',
            boxShadow: scrolledDown ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          }}
        >
          <div>
            <div
              className={`container-search ${isFocused ? 'focused' : ''}`}
              onClick={() => setIsFocused(true)}
              ref={searchBarRef}
            >
              <SearchBarResults
                ref={searchBarRef}
                id="search-bar"
                className={`${isFocused ? 'focused' : ''}`}
                onClick={() => setIsFocused(true)}
                isFocused={isFocused}
                filtersProps={props.filtersRedux}
                segment={props.segment}
                salueStyles={salueStyles}
                storageData={storageData}
                query={query}
                setQuery={setQuery}
                setIsSearching={() => {
                  setIsSearching(!isSearching);
                }}
              />
              <div></div>
              <ToggleMonitoring
                entities={entitiesMonitoring}
                disabled={false}
                filtersProps={props.filtersRedux}
              />
              <div
                style={{
                  position: 'relative',
                  background: '#fff',
                  width: '20%',
                  display: 'flex',

                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                {loadingJurimetria ? (
                  <Button
                    appearance="minimal"
                    size="sm"
                    icon={<Eye size="16" color=" #98A2B3" />}
                    iconPosition="left"
                    color="#98A2B3"
                    onClick={() => setIsOpen(true)}
                    style={{ color: '#98A2B3' }}
                  >
                    Jurimetria transparente
                  </Button>
                ) : (
                  'Carregando...'
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
              }}
            >
              <div
                className="monitoring-term"
                style={{ paddingLeft: '30px' }}
              ></div>
              <Nav
                resultPage={true}
                navItems={[
                  {
                    id: 1,
                    title: `Resultado da busca ${
                      !!props.countResults ? `(${props.countResults})` : ''
                    }`,
                  },
                  {
                    id: 2,
                    title: 'Jurimetria',
                  },
                ]}
                activeTab={activeIndex}
                handleChange={(index) => {
                  handleChangeNavigation(index);
                }}
              />
              <SLAJurimetric
                open={isOpen}
                handleClose={() => setIsOpen(false)}
                bases={bases}
                accuracy={accuracy}
                classified={classified}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-search-filter"
        style={{
          marginTop: selectedCards.length > 0 ? '10px' : '0px',
          transition: 'margin-top 0.2s ease-in-out',
        }}
      >
        <div
          className={`${
            colapse ? 'w-65 filter-search' : 'w-0'
          } transition-all duration-300 ease-in-out md:relative inset-y-0 z-20`}
        >
          {colapse && (
            <div
              className={
                selectedCards?.length > 0
                  ? 'box-filter_list_reduce'
                  : 'box-filter_list'
              }
            >
              <FiltersList
                headerTitle="Filtros de Pesquisa"
                AllProps={props}
                tmpFilters={props.tmpFilters}
                filtersProps={props.filtersRedux}
                entities={props.entities}
                setValueStyles={setValueStyles}
                scrolledDown={scrolledDown}
                search={props.search}
                setNewValue={setNewValue}
                setValitadionFilter={setValitadionFilter}
                setClearFiltro={setClearFiltro}
                query={query}
                colapse={colapse}
                setColapse={setColapse}
                selectedCards={selectedCards}
                isHome={isHome}
                requesting={requesting}
                isSearching={isSearching}
              />
            </div>
          )}
        </div>
        <div className="resultpage-container_content ">
          <div className="resultpage-content_results ">
            {!colapse && (
              <div
                className="flex gap-1 font-bold cursor-pointer ml-8"
                onClick={toggleFilterList}
              >
                <div
                  className="filter-title mt-1 font-bold"
                  style={{ fontSize: '16px' }}
                >
                  Filtros
                </div>

                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Abrir filtro</Tooltip>}
                >
                  <div className="filter-icon mt-1">
                    <Login size="25" color="#101828" />
                  </div>
                </Whisper>
              </div>
            )}

            {requesting ? (
              <div style={{ width: '100%' }}>
                <ResultContainerLoader />
              </div>
            ) : activeIndex === 1 && results.length > 0 && !requesting ? (
              <div>
                <div className="result-quantities-ordering">
                  <span className="resultpage-container_content_span">
                    Exibindo{' '}
                    <b className="font-semibold" style={{ color: '#101828' }}>
                      {' '}
                      {props?.countResults
                        ? props.countResults.toLocaleString('pt-BR')
                        : 0}
                    </b>{' '}
                    decisões e/ou normas encontrados em {resultsTime} segundos
                  </span>

                  {/* Linha separadora */}
                  <hr className="horizontal-line" />

                  <OrderResults
                    filtersProps={props.filtersRedux}
                    context="Results"
                    field_name="sort"
                  />
                </div>

                <ResultCardsContainer
                  query={query}
                  results={results}
                  validEntities={props.validEntities}
                  entities={props.entities}
                  loading={requesting}
                  theCollections={theCollections}
                  theMatchResultsThatHaveCollections={
                    theMatchResultsThatHaveCollections
                  }
                />
                {hasMore ? (
                  <div className="carregar-mais-button mt-5">
                    <Button
                      appearance="primary"
                      size="sm"
                      icon={<ArrowCircleDown2 size={16} color="#FFFFFF" />}
                      iconPosition="right"
                      onClick={() => loadMore()}
                    >
                      {loadingMore
                        ? 'Buscando mais resultados...'
                        : 'Carregar mais'}
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : activeIndex === 1 && results.length === 0 && !requesting ? (
              <div>
                <NoResultsMessage />
              </div>
            ) : activeIndex === 2 ? (
              <JurimetricsCenter
                searchPlots={searchPlots}
                requesting={requestingSearchJurimetrics}
                entities={props.entities}
                noJurimetricEntities={noJurimetricEntities}
                countResults={props.countResults}
                searchPlotsFunc={searchPlotsFunc}
                dataQuery={dataQuery}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Results);

import { ClipboardClose, Trash } from 'iconsax-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, SpinningLoader } from '../../../../../ds-components';
import './style.scss';
import { useCollectionTags } from '../../../../../store/ducks/collectionTags';

const DeleteTagButton = ({ tag, collectionId }) => {
  const [loading, setLoading] = useState(false);
  const { fetch: fetchCollectionTags } = useCollectionTags();

  function onDeleteTag() {
    setLoading(true);
    const endpoint = process.env.REACT_APP_COLLECTIONS;
    const options = { method: 'DELETE' };

    fetch(`${endpoint}api/tags/${tag.id}`, options)
      .then(() => {
        fetchCollectionTags(collectionId);
        toast.error(
          <span className="text-base leading-7 text-[#475467]">
            Marcador excluído da lista
          </span>,
          {
            icon: <ClipboardClose size="sm" color="#F04438" />,
            className: 'w-[312px] right-[26px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#F04438' },
          },
        );
      })
      .catch((err) => console.error(err))
      .finally(setLoading(false));
  }

  return (
    <Button
      size="sm"
      appearance="minimal"
      disabled={tag.active}
      onClick={tag.active ? null : onDeleteTag}
    >
      {loading ? (
        <SpinningLoader color="gray" size="sm" />
      ) : (
        <Trash color="#FDA29B" />
      )}
    </Button>
  );
};

export default DeleteTagButton;

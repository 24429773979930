import { Posthog } from '../posthog/posthog';
import { request } from './requester';

function getRelatorsByName(partialName) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'relator_by_partial_name',
    auth: 'token',
    data: {
      partial_name: partialName,
    },
  }).then((relators) => {
    return relators;
  });
}

function getRelatorJurimetricsByName(partialName) {
  return request({
    type: 'GET',
    microservice: 'jurimetrics',
    action: `relator_jurimetrics_by_name/${partialName}`,
    auth: 'token',
  }).then((relators) => {
    return relators;
  });
}

function getRelatorsById(relatorId) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `relator/${relatorId}`,
    auth: 'token',
  }).then((relator) => {
    return relator;
  });
}

function createVote(id, vote) {
  return request({
    type: 'POST',
    microservice: 'cards',
    action: `vote/${id}`,
    auth: 'token',
    data: {
      vote,
    },
  }).then((response) => {
    return response;
  });
}

function alterVote(id, vote) {
  return request({
    type: 'PATCH',
    microservice: 'cards',
    action: `vote/${id}`,
    auth: 'token',
    data: {
      vote,
    },
  }).then((response) => {
    return response;
  });
}

function getVotes(id) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `vote/${id}`,
    auth: 'token',
  }).then((votes) => {
    return votes;
  });
}

function getManyVotes(ids) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `vote`,
    auth: 'token',
    data: { card_id: ids },
  }).then((votes) => {
    return votes;
  });
}

function getAllEntities() {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `entities`,
    auth: 'token',
  }).then((entities) => {
    return entities;
  });
}

function getEntities(segmentation = 'tributario') {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `${segmentation}/entities`,
    auth: 'token',
  }).then((entities) => {
    return entities;
  });
}

function getCards(ids) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'cards',
    auth: 'token',
    data: { id: ids },
  }).then((cards) => {
    return cards;
  });
}

function getAllFilters() {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `filters`,
    auth: 'token',
  }).then((filters) => {
    return filters;
  });
}

function getFilters(segmentation = 'tributario') {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `${segmentation}/filters`,
    auth: 'token',
  }).then((filters) => {
    return filters;
  });
}

function search(dataQuery, etContext) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'search',
    auth: 'token',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: dataQuery,
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'search',
      ...dataQuery,
    });
    return response;
  });
}

function loadMore(dataQuery) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'search',
    auth: 'token',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: dataQuery,
  }).then((response) => {
    return response;
  });
}

function responseSeacrhInput(dataQuery) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'autocomplete',
    auth: 'token',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: dataQuery,
  }).then((response) => {
    return response;
  });
}

function searchCount(dataQuery) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'count',
    auth: 'token',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: dataQuery,
  }).then((count) => {
    return count;
  });
}

function searchHistory() {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'search_history',
    auth: 'token',
  }).then((history) => {
    return history;
  });
}

function searchGroupHistory(search = '', currentPage = 1, userID) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `grouped_history/?query=${search}&page=${currentPage}`,
    auth: 'token',
  }).then((history) => {
    return history;
  });
}

function searchArticles() {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: 'articles',
    auth: 'token',
  }).then((articles) => {
    return articles;
  });
}

export function getAllCardsByCollectionId(userId, collectionId) {
  try {
    const response = request({
      type: 'GET',
      microservice: 'cards',
      action: `cards/get_all_collections/${userId}/${collectionId}`,
      auth: 'token',
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar as coleções:', error);
    return null;
  }
}

export const cards = {
  getRelatorsByName,
  getRelatorsById,
  createVote,
  alterVote,
  getVotes,
  getManyVotes,
  getEntities,
  getAllEntities,
  getCards,
  getFilters,
  getAllFilters,
  search,
  loadMore,
  searchHistory,
  searchCount,
  getRelatorJurimetricsByName,
  searchArticles,
  responseSeacrhInput,
  getAllCardsByCollectionId,
  searchGroupHistory,
};

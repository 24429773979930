import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm'; // Suporte para GitHub Flavored Markdown
import remarkBreaks from 'remark-breaks'; // Força \n como quebra de linha
import 'katex/dist/katex.min.css';
interface MarkdownFormatterProps {
  markdownText: string;
}


const MarkdownFormatter = ({ markdownText }: MarkdownFormatterProps) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]} // Adiciona remark-breaks
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({ node, children }) => (
          <div>
            <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>{children}</p>
            <br></br>
          </div>
        ),
        li: ({ node, children }) => (
          <li style={{ textAlign: 'justify' }}>{children}</li>
        ),
        a: ({ href, children }) => (
          <a
            href={href}
            style={{
              color: 'mediumblue',
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        ),
      }}
    >
      {markdownText}
    </ReactMarkdown>
  );
};


export default MarkdownFormatter;

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Nav, Sidebar } from 'rsuite';
import { Sidenav as RSSidenav } from 'rsuite';
import './style.scss';
import { TURIVIUS_VERSION } from '../../app/App';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { Turivius } from '../../ui';
import { Whisper, Tooltip } from 'rsuite';

const Sidenav = ({
  defaultOpenKeys,
  handleSelectNavItem,
  header,
  footer,
  navItems,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [routeActive, setRouteActive] = useState('');
  const { pathname } = useLocation();

  const [isPesquisaPage, setIsPesquisaPage] = useState(false);
  const [showLogo, setShowLogo] = useState(isOpen);

  useEffect(() => {
    setIsPesquisaPage(pathname === '/pesquisa');
    if (pathname === '/pesquisa/resultados') {
      setIsOpen(false);
    }
    setRouteActive(pathname);
  }, [pathname]);

  useEffect(() => {
    localStorage.setItem('menu_open', isOpen);
    window.dispatchEvent(new Event('menuOpen'));
  }, [isOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogo(isOpen);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isOpen]);

  const sidebarClass = isPesquisaPage
    ? 'contente-sidebar pesquisa'
    : 'contente-sidebar resultados';

  return (
    <>
      {isOpen ? (
        <div className={sidebarClass}>
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>Fechar Menu</Tooltip>}
              >
                <Button
                  className="hidden-menu"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <ArrowLeft2 color="#053AC8" />
                </Button>
              </Whisper>
              <RSSidenav.Header>
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo"
                >
                  {showLogo && (
                    <img
                      src="/logo_white.png"
                      alt="Logo Turivius"
                      style={{ display: isOpen ? 'block' : 'none' }}
                    />
                  )}
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-container">
                  <div className="sidenav-body">
                    {navItems?.map((item, index) => (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) =>
                            i.show ? (
                              <Nav.Item
                                id={i.id}
                                key={index}
                                onClick={i.onClick}
                                className={
                                  i?.route === routeActive
                                    ? 'turivius-menu_option flex'
                                    : 'flex'
                                }
                              >
                                {i.route !== routeActive
                                  ? i.icon
                                  : i.icon_hover}

                                {i.name}
                              </Nav.Item>
                            ) : null,
                          )}
                        </Nav>
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className="line-footer"></div>
                    <div className="sidenav-footer">
                      <Nav className="turivius-module">
                        {footer?.map((item, index) => (
                          <Nav.Item
                            key={index}
                            onClick={item.onClick}
                            className="nav-item_footer"
                          >
                            <p className="turivius-module_name flex items-center">
                              {item.icon}
                              {item.children}
                            </p>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    <div className="text-white pl-4 text-xs ">
                      <br />v{TURIVIUS_VERSION}
                    </div>
                  </div>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      ) : (
        <div className={`${sidebarClass} close`}>
          <Sidebar className="turivius-sidebar">
            <RSSidenav
              defaultOpenKeys={defaultOpenKeys}
              expanded={true}
              onSelect={handleSelectNavItem}
              className="turivius-sidenav"
            >
              <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>Abrir menu</Tooltip>}
              >
                <Button
                  className="hidden-menu-close"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <ArrowRight2 color="#053AC8" />
                </Button>
              </Whisper>
              <RSSidenav.Header className="close">
                <Button
                  onClick={() => header.onClick()}
                  className="sidenav-header_logo_close"
                >
                  {!showLogo && (
                    <img
                      src="/logo_letra_white.png"
                      alt="Logo Turivius"
                      style={{ display: isOpen ? 'none' : 'block' }}
                    />
                  )}
                </Button>
              </RSSidenav.Header>
              <RSSidenav.Body>
                <div className="sidenav-container">
                  <div className="sidenav-body">
                    {navItems?.map((item, index) => (
                      <div key={index}>
                        <Nav>
                          {item.products?.map((i, index) =>
                            i.show ? (
                              <Nav.Item
                                id={i.id}
                                key={index}
                                onClick={i.onClick}
                                className={
                                  i?.route === routeActive
                                    ? 'turivius-menu_option_close'
                                    : ''
                                }
                              >
                                <Turivius.TuriviusTooltip
                                  trigger={'hover'}
                                  message={i.name}
                                  placement={'right'}
                                >
                                  {i.route !== routeActive
                                    ? i.icon
                                    : i.icon_hover}
                                </Turivius.TuriviusTooltip>
                              </Nav.Item>
                            ) : null,
                          )}
                        </Nav>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="line-footer-closed"></div>
                    <div className="sidenav-footer">
                      <Nav className="turivius-module">
                        {footer?.map((item, index) => (
                          <Nav.Item
                            key={index}
                            onClick={item.onClick}
                            className="nav-item_footer"
                          >
                            <Turivius.TuriviusTooltip
                              trigger={'hover'}
                              message={item.children}
                              placement={'right'}
                            >
                              <p className="turivius-module_name">
                                {item.icon}
                              </p>
                            </Turivius.TuriviusTooltip>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                    {/* <div className="text-white pl-5 text-xs "></div> // TODO: version letra branca */}
                    <div className="text-white pl-5 text-xs ">
                      <br />v{TURIVIUS_VERSION}
                    </div>
                  </div>
                </div>
              </RSSidenav.Body>
            </RSSidenav>
          </Sidebar>
        </div>
      )}
    </>
  );
};

export default Sidenav;

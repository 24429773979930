import { useEffect, useState } from 'react';
import { SelectPicker } from 'rsuite';
import { posthog } from 'posthog-js';
import './order-style.css';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

const dropdownData = [
  { id: 1, value: 1, label: 'Relevância' },
  { id: 2, value: 2, label: 'Data de Publicação' },
];

const OrderResults = ({ filtersProps, context, field_name }) => {
  const [value, setValue] = useState(null);

  const handleChange = (value) => {
    if (context && context != 'Monitoring') {
      posthog.capture(context, {
        action: 'change_filter',
        id: field_name,
      });
      postHubSpotEvent('app_changefilters', { app_changefilters: context });
    }
    setValue(value);
    filtersProps.setTmpFilters(field_name, value);
    filtersProps.saveFilters(filtersProps.history, filtersProps.match);
  };

  useEffect(() => {
    setValue(filtersProps.tmpFilters[field_name]);
  }, []);

  return (
    <div className="order-results">
      <div className="order-tour-container">
        <span>Ordenar: </span>
        <SelectPicker
          size="xs"
          className="custom-select-picker" // classe personalizada
          appearance="subtle"
          data={dropdownData}
          placeholder={'Selecione uma ordenação'}
          searchable={false}
          value={value}
          onChange={(value) => handleChange(value)}
          cleanable={false}
        />
      </div>
    </div>
  );
};

export default OrderResults;

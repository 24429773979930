import React from 'react';
import './TooltipTurivius.scss';

const TooltipTurivius = ({ text, children, position = 'top', style = {} }) => {
  const tooltipPosition = `tooltip-${position}`;

  return (
    <span className="tooltip-wrapper">
      {children}
      <div className={`tooltip ${tooltipPosition}`} style={style}>{text}</div>
    </span>
  );
};

export default TooltipTurivius;

import { useState } from 'react';
import { Message, toaster } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requester } from '../../../../../requesters';
import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { TickCircle } from 'iconsax-react';
import { useCollections } from '../../../../../store/ducks/theCollections';
import { toast } from 'react-toastify';
import redCrossIcon from '../../../../../assets/svgs/red-cross.svg';
import SeeCollectionMessage from '../BulkActionBar/SeeCollectionMessage';

const AddCardToReadLaterButton = ({
  cards,
  context,
  appearance,
  icon,
  ...props
}) => {
  const { fetch } = useCollections();
  const [loading, setLoading] = useState(false);

  const verifyAndGetCompanyProfiles = () => {
    const {
      companiesProfiles,
      fetchingCompaniesProfiles,
      toggleFetchingCompanyProfiles,
      setCompanyProfiles,
    } = props;
    const company = localStorage.getItem('company');
    if (!companiesProfiles[company] && !fetchingCompaniesProfiles[company]) {
      toggleFetchingCompanyProfiles({ [company]: true });
      requester.profiles
        .getCompanyUserNames(company, '')
        .then((response) => {
          setCompanyProfiles({ [company]: response });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => toggleFetchingCompanyProfiles({ [company]: false }));
    }
  };

  const addCardToReadLaterHandler = async (cards) => {
    const cardIds = cards.map((card) => card.id);
    setLoading(true);

    try {
      const response = await requester.collections.addCardsToReadLater(
        localStorage.getItem('user'),
        cardIds,
        props.context,
      );
      fetch();
      if (props.companiesProfiles[localStorage.getItem('company')]) {
      } else {
        verifyAndGetCompanyProfiles();
      }
      setLoading(false);
      toast.success(
        <SeeCollectionMessage
          collectionId={response.boardId}
          notificationContent={
            cardIds.length === 1
              ? 'Adicionado'
              : 'Adicionados' + 'a coleção \n "Ler mais tarde"'
          }
        />,
        {
          icon: <TickCircle size="sm" color="#12B76A" />,
          className: 'w-[362px] right-[50px] bottom-[50px] cursor-pointer',
          progressStyle: { background: '#12B76A' },
          closeButton: false,
        },
      );
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (err.message.error === 'COLLECTION_SIZE_EXCEEDED')
        toast.error(
          'Você não pode adicionar mais que 300 decisões a sua coleção.',
          {
            icon: <img src={redCrossIcon} style={{ width: '16px' }} />,
          },
        );
      else
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente.
          </Message>,
          { duration: 4000 },
        );
    }
  };

  return (
    <Button
      size="sm"
      appearance={appearance}
      icon={loading ? <SpinningLoader size="sm" color="gray" /> : icon}
      onClick={() => addCardToReadLaterHandler(cards)}
      className="bg-[#102F7F]"
      disabled={loading}
    >
      Ler mais tarde
    </Button>
  );
};

const mapStateToProps = (state) => ({
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ProfilesActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCardToReadLaterButton);

import { Login } from 'iconsax-react';
import HeaderFilter from './components/HeaderFilter/HeaderFilter';
import { Tooltip, Whisper } from 'rsuite';
import './NewFilterList.scss';
import { useState } from 'react';

export const NewFilterList = ({
  updateSelectedFilters,
  setValueStyles,
  search,
  filtersProps,
  setNewValue,
  setValitadionFilter,
  handleStateUpdate,
  setClearFiltro,
  trigger,
  setTrigger,
  setRefreshRelator,
  colapse,
  setColapse,
  setClear,
  query,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleModal = (type) => {
    setModalType(type);
    setIsOpenModal(true);
  };

  const CloseModal = () => {
    setIsOpenModal(false);
    setModalType('');
  };

  const toggleFilterList = () => {
    setColapse(!colapse);
  };

  const clearFilter = () => {
    const filters = JSON.parse(localStorage.getItem('filters')) || {};

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const todayFormatted = `${day}/${month}/${year}`;

    filters.date_range = {
      0: '01/01/2015',
      1: todayFormatted,
    };

    filters.filter_by_secao_carf = [];
    filters.filter_by_vote = [];
    filters.filter_by_relator = [];
    filters.filter_by_favorability = [];
    filters.entities = [
      0, 1, 2, 97, 95, 96, 3, 40, 41, 5, 7, 8, 43, 11, 12, 13, 42, 15, 17, 51,
      18, 19, 23, 24, 25, 26, 28, 29, 27, 6, 4, 31, 33, 10, 30, 32, 34, 36, 37,
      35, 16, 57, 60, 39, 66, 56, 49, 45, 72, 65, 73, 74, 38, 64, 63, 69, 55,
      75, 68, 71, 9, 78, 52, 21, 70, 54, 22, 91, 58, 93, 53, 90, 20, 82, 85, 83,
      86, 80, 79, 87, 48, 94, 50, 89, 77, 44, 46, 59, 84,
    ];

    localStorage.setItem('filters', JSON.stringify(filters));
  };

  const clearDefault = () => {
    setClear(true);
    clearFilter();
  };

  return (
    <div className="box-filter-header">
      <div className="filter-title">Filtros</div>
      <div className="options-nav">
        <div className="filter-actions">
          <Whisper
            placement="top"
            trigger="hover"
            speaker={
              <Tooltip>Volte a configuração inicial dos filtros</Tooltip>
            }
          >
            <span
              onClick={() => {
                clearDefault();
                setTimeout(() => {
                  filtersProps.saveFilters(
                    filtersProps.history,
                    filtersProps.match,
                    encodeURIComponent(query),
                  );
                }, 100); // Aqui atualiza quando limpa, precisa de um timeout para carregar os dados no localstorage
                setRefreshRelator();
              }}
            >
              Limpar Filtros
            </span>
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Salve esse filtro como modelo</Tooltip>}
          >
            <span onClick={() => handleModal('salvar')}>Salvar</span>
          </Whisper>
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Carregar seus filtros de modelo</Tooltip>}
          >
            <span onClick={() => handleModal('meusFiltros')}>Meus filtros</span>
          </Whisper>
        </div>

        <div className="filter-icon cursor-pointer">
          <Whisper
            placement="top"
            trigger="hover"
            speaker={<Tooltip>Recolher filtro</Tooltip>}
          >
            <Login
              size="24"
              color="#101828"
              onClick={toggleFilterList}
              style={{ transform: 'rotate(180deg)' }}
            />
          </Whisper>
        </div>
        {isOpenModal && (
          <HeaderFilter
            CloseModal={CloseModal}
            isCarregarFiltros={modalType === 'meusFiltros'}
            updateSelectedFilters={updateSelectedFilters}
            search={search}
            filtersProps={filtersProps}
            setNewValue={setNewValue}
            handleStateUpdate={handleStateUpdate}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
      </div>
    </div>
  );
};

import PropTypes from 'prop-types';
import { Loader } from 'rsuite';
import './TuriviusPreloader.scss';
const TuriviusPreloader = ({
  backdrop = false,
  source = '/favicon.svg',
  visible = true,
  style = {},
}) => {
  if (!visible) return null;

  return (
    <>
      <div
        className={`turivius-preloader ${backdrop ? 'backdrop' : ''} ${visible ? 'visible' : ''}`}
        aria-hidden={!visible}
      >
        <div className="turivius-spinner">
          <Loader vertical size="lg" />
          <img
            className="turivius-spinner-image"
            src={source}
            style={style}
            alt="Carregando..."
          />
        </div>
      </div>
    </>
  );
};

TuriviusPreloader.propTypes = {
  backdrop: PropTypes.bool,
  style: PropTypes.object,
  source: PropTypes.string,
  visible: PropTypes.bool,
};

export default TuriviusPreloader;

import FiltersList from '../FilterList/FilterList';
import './advancedFilters.scss';
import { CloseCircle } from 'iconsax-react';

const AdvancedFilters = (props) => {
  const drawerClass = props.open ? 'drawer open' : 'drawer';

  const handleDrawerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div style={{ cursor: 'default' }} onClick={handleDrawerClick}>
      <div className={drawerClass}>
        <button
          className="button-drawer"
          onClick={props.onClose}
          style={{ float: 'right' }}
        >
          <CloseCircle size="24" color="#292D32" />
        </button>

        <div className="header-filter-home">
          <h1>Novas bases de dados</h1>
        </div>

        <div className="drawer-content">
          <FiltersList
            AllProps={props.AllProps}
            tmpFilters={props.tmpFilters}
            filtersProps={props.filtersProps}
            entities={props.entities}
            clearSelectedFilters={props.clearFilters}
            query={props.query}
            isFilterHome={true}
          />
        </div>
      </div>
      {props.open && <div className="backdrop" onClick={props.onClose} />}
    </div>
  );
};

export default AdvancedFilters;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toaster, Message, Button, ButtonToolbar, Form, Divider } from 'rsuite';
import { ArrowRight2 } from 'iconsax-react';

const TuriviusLoginSSO = ({ logo = '', fetching = false, onLog }) => {
  const formForget = React.useRef(null);

  const [email, setEmail] = useState({ email: '' });
  const [recoverPasswordFormError, setRecoverPasswordFormError] = useState({});

  const handleSubmitLogin = () => {
    if (email) {
      onLog(email);
    } else {
      toaster.push(
        <Message
          showIcon
          closable
          type="warning"
          duration={5000}
          header="Campos inválidos"
        >
          Preencha os campos e tente novamente
        </Message>,
      );
    }
  };

  const keyPressLogin = (e) => {
    if (e.key === 'Enter') {
      handleSubmitLogin();
    }
  };

  return (
    <div className="login-component">
      <div className="logo-image">
        <img src={logo} alt="Turivius" />
      </div>

      <div className="login-component_header">
        <h2>SSO</h2>
        <span>Insira seus dados para logar com Single Sign On</span>
      </div>

      <Divider />

      <div className="login-component_body">
        <Form
          fluid
          ref={formForget}
          onChange={setEmail}
          formValue={email}
          formDefaultValue={email}
          readOnly={fetching}
          formError={recoverPasswordFormError}
        >
          <Form.Group controlId="forgot-password">
            <Form.ControlLabel name="email">
              Digite seu email empresarial
            </Form.ControlLabel>
            <Form.Control name="email" />
          </Form.Group>

          <Form.Group>
            <ButtonToolbar className="container-buttons">
              <Button
                block
                className="login-component_body_login-button"
                onClick={handleSubmitLogin}
                loading={fetching}
              >
                <label>
                  <ArrowRight2 color="#FFFFFF" size={16} />
                  Entrar
                </label>
              </Button>

              <Button
                block
                className="login-component_body_back-button"
                onClick={() => (window.location.href = '/login')}
                loading={fetching}
              >
                Voltar
              </Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </div>
    </div>

    // <div className="login-page">
    //   <Container>
    //     <Content>
    //       <FlexboxGrid justify="center">
    //         <FlexboxGrid.Item colspan={20}>
    //           <div style={{ marginBottom: 16 }}>{loginHeader}</div>
    //           {inside ? null : (
    //             <div className={`logo-image ${inside ? "inside" : "outside"}`}>
    //               <img src={logo} alt="Turivius" />
    //             </div>
    //           )}
    //           <Panel
    //             className="box-login"
    //             header={
    //               inside ? (
    //                 <div>
    //                   <div
    //                     className={`logo-image ${
    //                       inside ? "inside" : "outside"
    //                     }`}
    //                   >
    //                     <img src={logo} alt="Turivius" />
    //                   </div>
    //                   <h4>{page === "login" ? null : "Recuperar Senha"}</h4>
    //                 </div>
    //               ) : (
    //                 <div>
    //                   <h4>{page === "login" ? null : "Recuperar Senha"}</h4>
    //                 </div>
    //               )
    //             }
    //           >
    //             <Form
    //               fluid
    //               ref={formForget}
    //               onChange={setEmail}
    //               formValue={email}
    //               formDefaultValue={email}
    //               readOnly={fetching}
    //               formError={recoverPasswordFormError}
    //             >
    //               <Form.Group controlId="forgot-password">
    //                 <Form.ControlLabel name="email">
    //                   Digite seu email empresarial
    //                 </Form.ControlLabel>
    //                 <Form.Control name="email" />
    //               </Form.Group>

    //               <Form.Group>
    //                 <ButtonToolbar className="container-buttons">
    //                   <Button
    //                     block
    //                     className="btn-login font-medium mb-3"
    //                     onClick={handleSubmitLogin}
    //                     loading={fetching}
    //                   >
    //                     Entrar
    //                     <ArrowRight2 color="#FFFFFF" size={16} />
    //                   </Button>

    //                   {/* <div className="horizontal-divisor" /> */}
    //                   <TuringButton
    //                     appearance="secondary"
    //                     size="md"
    //                     fullWidth
    //                     disabled={fetching}
    //                     onClick={() => (window.location.href = "/login")}
    //                   >
    //                     Voltar
    //                   </TuringButton>
    //                 </ButtonToolbar>
    //               </Form.Group>
    //             </Form>
    //           </Panel>
    //           <div style={{ marginTop: 12 }}>{loginFooter}</div>
    //         </FlexboxGrid.Item>
    //       </FlexboxGrid>
    //     </Content>
    //   </Container>
    // </div>
  );
};
// };

TuriviusLoginSSO.propTypes = {
  page: PropTypes.string,
  logo: PropTypes.string,
  fetching: PropTypes.bool,
  // recoverPasswordValue: PropTypes.object,
  // recoverPasswordError: PropTypes.object,
  // loginFormError: PropTypes.object,
  // loginFormValue: PropTypes.object,
  onNewPassword: PropTypes.func,
  onLog: PropTypes.func,
  loginHeader: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  goLogin: PropTypes.func,
  goForgotPassword: PropTypes.func,
  loginFooter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TuriviusLoginSSO;

import React, { useState, useEffect } from 'react';
import { Button, Loader, TagPicker } from 'rsuite';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import './MultiSelect.css';

const MultiSelect = ({
  resultById,
  incomingItems,
  filtersProps,
  filter,
  placeholder,
  updateSelectedFilters,
  clear,
  setClear,
  context,
}) => {
  const [requesting, setRequesting] = useState(true);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    populateTagPickerFromResultById();
  }, [resultById]);

  useEffect(() => {
    if (incomingItems) {
      setItems(filtersProps.externalFilters[filter.field_name] || []);
    }
  }, [incomingItems]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const loadedItems = await loadItems();
        populateTagPickerFromResultById();
        if (loadedItems.length > 0) {
          setItems(loadedItems);
          setRequesting(false);
          loadValuesFromUrl();
        } else {
          setRequesting(false);
        }
      } catch (error) {
        console.error('Erro ao carregar os itens:', error);
        setRequesting(false);
      }
    };

    setTimeout(() => {
      loadData();
    }, 6000);

    logFilterByRelatorParams();
  }, []);

  useEffect(() => {
    if (clear) {
      handleClearChange();
    }
  }, [clear]);

  useEffect(() => {
    setSelectedCount(value.length);
    if (filtersProps?.onSelectionChange) {
      filtersProps.onSelectionChange(value.length);
    }
  }, [value]);

  const populateTagPickerFromResultById = () => {
    const resultById = JSON.parse(
      localStorage.getItem('filters'),
    ).filter_by_relator;
    if (resultById) {
      const matchedValues = matchValuesWithItems(
        resultById.map((id) => id.toString()),
      );
      setValue(matchedValues);
      filtersProps.setTmpFilters(filter.field_name, matchedValues);
      updateSelectedFilters({ [filter.field_name]: matchedValues });
    }
  };

  const loadItems = async () => {
    try {
      const externalFilters = filtersProps.externalFilters[filter.field_name];
      return externalFilters
        ? externalFilters.map((filter) => ({ ...filter, id: filter.key }))
        : [];
    } catch (error) {
      return [];
    }
  };

  const loadValuesFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filters = urlParams.get('filters');
    if (filters) {
      try {
        const filtersObject = JSON.parse(filters);
        const filterByRelator = filtersObject.filter_by_relator;
        if (filterByRelator) {
          const selectedValues = filterByRelator.map((item) => item.toString());
          const matchedValues = matchValuesWithItems(selectedValues);
          setValue(matchedValues);
          filtersProps.setTmpFilters(filter.field_name, matchedValues);
          updateSelectedFilters({ [filter.field_name]: matchedValues });
        }
      } catch (error) {
        console.error("Erro ao parsear o JSON de 'filters':", error);
      }
    }
  };

  const logFilterByRelatorParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filters = urlParams.get('filters');
    if (filters) {
      try {
        JSON.parse(filters);
      } catch (error) {
        console.error("Erro ao parsear o JSON de 'filters':", error);
      }
    } else {
      console.log("Nenhum parâmetro 'filters' encontrado na URL");
    }
  };

  const matchValuesWithItems = (selectedValues) => {
    if (!items.length) return selectedValues;
    return selectedValues
      .map((val) => {
        const matchedItem = items.find(
          (item) =>
            item.id.toString() === val ||
            item.key.toLowerCase() === val.toLowerCase() ||
            item.related_names.toLowerCase().includes(val.toLowerCase()),
        );
        return matchedItem ? matchedItem.related_names : val;
      })
      .filter(Boolean);
  };

  const cleanDates = () => {
    setValue([]);
    filtersProps.setTmpFilters(filter.field_name, []);
    updateSelectedFilters({ [filter.field_name]: [] });
    handleClear();
  };

  const updateUrlParams = (ids) => {
    let filtersLocal = JSON.parse(localStorage.getItem('filters'));
    filtersLocal.filter_by_relator = ids;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('filters', JSON.stringify(filtersLocal));
    const paramsStringDecode = decodeURIComponent(urlParams.toString());
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${paramsStringDecode}`,
    );
  };

  const handleClearChange = () => {
    cleanDates();
    setClear(false);
  };

  const handleClear = () => {
    setSelectedCount(0);
    if (filtersProps?.onSelectionChange) {
      filtersProps.onSelectionChange(0, true);
    }
  };

  const formatRelatorPresentation = (relator_info) => {
    const isAtHome = window.location.pathname === '/pesquisa';

    let countText = '';
    if (relator_info?.doc_count) {
      countText = isAtHome ? ' ' : `(${relator_info.doc_count})`;
    }

    const reponse =
      relator_info?.id?.length > 26
        ? relator_info.id.slice(0, 26) + '... ' + countText
        : relator_info.id + countText;

    return reponse;
  };

  return (
    <div
      // style={{ paddingBottom: '24px' }}
      className="single-filter-box tagpicker-container"
      id={filter.field_name}
    >
      <div className="single-filter-box_container-title">
        <span className="single-filter-box_title">{filter.public_name}</span>
        <Button size="xs" appearance="link" onClick={cleanDates}>
          Limpar
        </Button>
      </div>
      {!requesting ? (
        <TagPicker
          virtualized
          block
          id={`rs-select-${filter.field_name}`}
          labelKey="id"
          valueKey="related_names"
          data={items
            .map((item) => ({
              ...item,
              id: formatRelatorPresentation(item),
            }))
            .filter((item) => item.related_names !== null)}
          placeholder={placeholder}
          cleanable={false}
          placement="top"
          container={() => document.getElementById(filter.field_name)}
          value={value}
          onChange={(valor) => {
            const selectedIds = valor.map((v) =>
              typeof v === 'string' ? v : v['related_names'],
            );

            let rightRelatorList = [];
            if (valor.length > 0) {
              rightRelatorList = valor.reduce((acc, relatorName) => {
                if (relatorName.indexOf(',') !== -1) {
                  return [...acc, ...relatorName.split(',')];
                } else {
                  return [...acc, relatorName];
                }
              }, []);
            }

            setValue(valor);
            filtersProps.setTmpFilters(filter.field_name, rightRelatorList);
            updateSelectedFilters({
              [filter.field_name]: rightRelatorList,
            });
            updateUrlParams(rightRelatorList);

            if (context) {
              postHubSpotEvent('app_changefilters', {
                app_changefilters: context,
              });
            }
          }}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MultiSelect;

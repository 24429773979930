import PropTypes from 'prop-types';
import './TuriviusSwarmChart.scss';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';

const TuriviusSwarmChart = ({
  id = undefined,
  className = '',
  style = {
    width: 400,
    height: 300,
  },
  data = [],
  groups = [],
  value = 'timestamp',
  valueFormat = (value) => value.toLocaleString('pt-BR')?.split(' ')[0],
  valueScale = {
    format: '%Y-%m-%dT%H:%M:%S.%LZ',
    type: 'time',
  },
  simulationIterations = 100,
  margin = {
    top: 40,
    right: 100,
    bottom: 40,
    left: 40,
  },
  size = undefined,
  axisTop = {
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
  },
  axisLeft = {
    format: '',
    legendPosition: 'middle',
    legendOffset: -20,
  },
  axisBottom = null,
  axisRight = {
    format: (value) => value.toLocaleString('pt-BR')?.split(' ')[0],
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
  },
  layout = 'vertical',
  getColors = undefined,
  onClick = () => {},
  tooltip = undefined,
  isInteractive = true,
  animate = true,
  ...params
}) => (
  <div
    id={id}
    data-testid={`swarm-chart-${id}`}
    className={`turivius-swarm-chart ${className}`}
    style={style}
  >
    <ResponsiveSwarmPlot
      data={data}
      groups={groups}
      value={value}
      valueFormat={valueFormat}
      valueScale={valueScale}
      simulationIterations={simulationIterations}
      margin={margin}
      size={size}
      axisTop={axisTop}
      axisLeft={axisLeft}
      axisBottom={axisBottom}
      axisRight={axisRight}
      layout={layout}
      colors={getColors}
      onClick={onClick}
      tooltip={tooltip}
      isInteractive={isInteractive}
      {...params}
    />
  </div>
);

TuriviusSwarmChart.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.array,
  groups: PropTypes.array,
  value: PropTypes.string,
  valueFormat: PropTypes.func,
  valueScale: PropTypes.object,
  simulationIterations: PropTypes.number,
  margin: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  axisTop: PropTypes.object,
  axisLeft: PropTypes.object,
  axisRight: PropTypes.object,
  axisBottom: PropTypes.object,
  layout: PropTypes.string,
  getColors: PropTypes.func,
  onClick: PropTypes.func,
  tooltip: PropTypes.func,
  isInteractive: PropTypes.bool,
};

export default TuriviusSwarmChart;

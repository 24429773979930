import React from 'react';
import { Message, toaster } from 'rsuite';
import PropTypes from 'prop-types';

const push = ({
  title = '',
  message = '',
  type = 'info',
  withIcon = true,
  closable = true,
  duration = 5000,
  style,
}) =>
  toaster.push(
    <Message
      style={style}
      closable={closable}
      showIcon={withIcon}
      type={type}
      header={title}
      duration={duration}
    >
      {message}
    </Message>,
  );

push.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  withIcon: PropTypes.bool,
  closable: PropTypes.bool,
  duration: PropTypes.number,
  style: PropTypes.object,
  placement: PropTypes.oneOf([
    'topCenter',
    'bottomCenter',
    'topStart',
    'topEnd',
    'bottomStart',
    'bottomEnd',
  ]),
};

const remove = ({ key = '' }) => toaster.remove(key);

remove.propTypes = {
  key: PropTypes.string,
};

const clear = () => toaster.clear();

clear.propTypes = {};

const TuriviusAlertMessage = {
  push,
  remove,
  clear,
};

export default TuriviusAlertMessage;

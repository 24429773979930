import React from 'react';
import { Button, Col, Dropdown, Tooltip, Whisper } from 'rsuite';
import { Turivius } from '../../../../../ui';
import { Textfit } from 'react-textfit';

import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { Buildings, Calendar2, Lock, More, Profile2User } from 'iconsax-react';

import EditCollectionButton from '../../Collection/EditCollectionButton/EditCollectionButton';
import RemoveCollectionButton from '../../Collection/RemoveCollectionButton/RemoveCollectionButton';
import ManagePeopleCollectionButton from '../../Collection/ManagePeopleCollectionButton/ManagePeopleCollectionButton';
import ShareCollectionWithCompanyButton from '../../Collection/ShareCollectionWithCompanyButton/ShareCollectionWithCompanyButton';
import AvatarFolder from '../AvatarFolder';

import 'moment/locale/pt-br';
import './CollectionFolder.scss';

const renderToggleMenu = (props, ref) => {
  return (
    <Whisper
      trigger="hover"
      onClick={(e) => e.stopPropagation()} // Isso serve para que no momento que o usuário clicar nas opções ele não entre na coleção
      placement="top"
      speaker={<Tooltip> Opções</Tooltip>}
    >
      <Button {...props} ref={ref} className="btn-menu-option">
        <More size="16" />
      </Button>
    </Whisper>
  );
};

class CollectionFolder extends React.Component {
  state = {
    requesting: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getOwnerName(name) {
    const splitName = name.split(' ');
    if (splitName.length <= 1) {
      return name;
    } else {
      return `${splitName[0][0]}. ${splitName.pop()}`;
    }
  }

  openCollection(collectionId) {
    this.props.history.push({
      pathname: `/colecoes/${collectionId}`,
    });
  }

  openCollectionDrawer(collectionId) {
    this.props.history.push({
      pathname: `/colecoes/${collectionId}`,
      search: `?openDrawer=true`,
    });
  }

  render() {
    const { collection, shared, onSearch, id } = this.props;

    const { anchorEl } = this.state;

    return (
      <Col sm={24} md={12} lg={8} xl={6}>
        <div
          className="collection-folder_content"
          id={id}
          onClick={() => this.openCollection(collection.id)}
        >
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            // onClick={(e) => e.stopPropagation()}
          >
            <div className="box-card">
              <div className="more-title">
                <AvatarFolder data={collection} />

                <div className="title-folder">
                  <Turivius.TuriviusTooltip
                    trigger="hover"
                    placement="top"
                    message={collection.name}
                  >
                    {collection.name.length > 13
                      ? `${collection.name.substring(0, 13)}...`
                      : collection.name}
                  </Turivius.TuriviusTooltip>
                </div>
              </div>
            </div>

            <Dropdown
              placement="bottomEnd"
              renderToggle={renderToggleMenu}
              id="nav-menu-options"
            >
              <div className="title-contents">
                <h2>Ações</h2>
              </div>
              <Dropdown.Item
                eventKey={1}
                onClick={(e) => e.stopPropagation()} // Isso serve para que no momento que o usuário clicar nas opções ele não entre na coleção
              >
                <ShareCollectionWithCompanyButton
                  collection={collection}
                  collectionId={collection.id}
                  collectionTitle={collection.name}
                  publicToCompany={collection.public_to_company}
                  onSearch={onSearch}
                  context={'Collections'}
                />
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={2}
                onClick={(e) => e.stopPropagation()} // Isso serve para que no momento que o usuário clicar nas opções ele não entre na coleção
              >
                <ManagePeopleCollectionButton
                  collection={collection}
                  collectionId={collection.id}
                  sharedWith={collection.shared_with}
                  context={'Collections'}
                  onSearch={onSearch}
                />
              </Dropdown.Item>

              {shared ? (
                <Dropdown.Item
                  eventKey={2}
                  onClick={(e) => e.stopPropagation()} // Isso serve para que no momento que o usuário clicar nas opções ele não entre na coleção
                >
                  <EditCollectionButton
                    collectionId={collection.id}
                    collectionTitle={collection.name}
                    collectionDescription={collection.description}
                    onSearch={onSearch}
                    context={'Collections'}
                  />
                </Dropdown.Item>
              ) : null}

              {shared ? (
                <Dropdown.Item
                  onClick={(e) => {
                    e.stopPropagation(); // Isso serve para que no momento que o usuário clicar nas opções ele não entre na coleção
                    this.handleClose(e);
                  }}
                >
                  <RemoveCollectionButton
                    collection={collection}
                    collectionId={collection.id}
                    context={'Collections'}
                    onSearch={onSearch}
                  />
                </Dropdown.Item>
              ) : null}
            </Dropdown>
          </div>
          <div className="decisions">
            <span>{collection?.qt_cards} decisões</span>
          </div>

          {/* <div className="box-button-card-modal">
            <Whisper
              trigger="hover"
              placement="top"
              speaker={
                <Tooltip style={{ whiteSpace: 'nowrap', maxWidth: 'none' }}>
                  Faça qualquer pergunta a sua coleção
                </Tooltip>
              }
            >
              <button
                className="button-card-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  this.openCollectionDrawer(collection.id);
                }}
              >
                <img
                  className="icon-stars-button-modal"
                  src="/stars-group.png"
                  alt="Ícone de estrelas"
                />
                <span>Pergunte a sua coleção</span>
              </button>
            </Whisper>
          </div> */}
          <div className="box-footer-card">
            <div className="box-footer-content">
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip> Última atualização</Tooltip>}
              >
                <div className="content-calendar">
                  <Calendar2 size="13" color="#98a2b3" />
                  <Textfit mode="single" max={13}>
                    Atualizado&thinsp;
                    <Moment fromNow locale="pt-br" parse="YYYY-MM-DD hh:mm:ss">
                      {collection.last_update}
                    </Moment>
                  </Textfit>
                </div>
              </Whisper>

              <div>
                {collection.public_to_company ? (
                  <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={
                      <Tooltip>Compartilhada com todo o escritório</Tooltip>
                    }
                  >
                    <div className="info-icon-footer">
                      <Buildings
                        color="#98a2b3"
                        size={12}
                        style={{ marginRight: 3 }}
                      />
                      <span style={{ fontSize: 10 }}>Escritório</span>
                    </div>
                  </Whisper>
                ) : collection.shared_with.length > 0 ? (
                  <div className="info-icon-footer">
                    <Profile2User
                      color="#98a2b3"
                      size={12}
                      style={{ marginRight: 3 }}
                    />
                    <Whisper
                      trigger="hover"
                      placement="top"
                      speaker={
                        <Tooltip>Compartilhada com outros usuários</Tooltip>
                      }
                    >
                      <span style={{ fontSize: 10 }}>
                        {collection.shared_with.length} usuário(s)
                      </span>
                    </Whisper>
                  </div>
                ) : (
                  <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={<Tooltip>Compartilhada somente com você</Tooltip>}
                  >
                    <div className="info-icon-footer">
                      <Lock
                        color="#98a2b3"
                        size={12}
                        style={{ marginRight: 3 }}
                      />
                      <span style={{ fontSize: 10 }}>Somente eu</span>
                    </div>
                  </Whisper>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

export default withRouter(CollectionFolder);

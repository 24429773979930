import {
  ArrowDown2,
  ArrowRight2,
  ArrowUp2,
  Calendar,
  ClipboardClose,
  Judge,
  Tag,
  UserEdit,
} from 'iconsax-react';
import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip, Whisper } from 'rsuite';
import { Button } from '../../../../../../ds-components';
import { Types as BulkactionTypes } from '../../../../../../store/ducks/collectionsBulkaction';
import AddTagDecision from '../../../../../collections/components/Collection/AddTagDecision';
import DecisionsTimeline from '../../Buttons/DecisionsTimeline';
import ReadMoreOrLessButton from '../../Buttons/ReadMoreOrLessButton';
import ResultButtonGroup from '../../Buttons/ResultButtonGroup';
import FavorabilityModal from '../../Modals/FavorabilityModal';
import MoreInfoResultCard from './MoreInfoResultCard';
import { ResultCardInteiroTeor } from './ResultCardInteiroTeor';
import { Badge } from './badge';
import { cn } from '../../../../../../lib/utils';
import { Checkbox } from '../../../../../../components/ui/checkbox';
import DOMPurify from 'dompurify';
import './ResultCard.scss';

export const ResultCard = ({
  card,
  collectionId,
  query,
  theMatchResultsThatHaveCollections,
  collectionTags,
}) => {
  const { search, pathname } = useLocation();
  const [showAllText, setShowAllText] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [maxTokens, setMaxTokens] = useState(150);
  const contentRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState([]);

  const hasFullText = useMemo(() => search.search('full_text') > -1, [search]);
  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const isSelected = useMemo(
    () => selectedCards.some((selectedCard) => selectedCard.id === card.id),
    [selectedCards],
  );

  const dispatch = useDispatch();

  const bulkactionCheckboxHandler = () => {
    if (selectedCards.some((selectedCard) => selectedCard.id === card.id))
      dispatch({ type: BulkactionTypes.UNSELECT_CARDS, cards: [card] });
    else if (selectedCards.length >= 30)
      toast.warning(
        <span className="text-base leading-7 text-[#475467]">
          Você pode selecionar até 30 decisões no máximo
        </span>,
        {
          icon: <ClipboardClose size="sm" color="#F79009" />,
        },
      );
    else dispatch({ type: BulkactionTypes.SELECT_CARDS, cards: [card] });
  };

  const handleOnMouseEnter = () => {
    setIsBeingHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsBeingHovered(false);
  };

  const countTokens = (text) => {
    return text.split(/\s+/).length;
  };

  // Função para sanitizar o conteúdo HTML
  const sanitizeContent = (content) => {
    return DOMPurify.sanitize(content);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    bulkactionCheckboxHandler();
  };

  const getEmentaDecisaoContent = () => {
    const ementaSection = card.content.find(
      (section) => section.title === 'Ementa',
    );
    const decisaoSection = card.content.find(
      (section) => section.title === 'Decisão',
    );

    let ementaContent = ementaSection ? ementaSection.content : '';
    let decisaoContent = decisaoSection ? decisaoSection.content : '';

    // Tokens da ementa
    const ementaTokens = ementaContent.split(/\s+/);
    const ementaTokensCount = ementaTokens.length;
    const maxTokens = 150;

    let totalTokens;

    if (ementaTokensCount < maxTokens) {
      const remainingTokens = maxTokens - ementaTokensCount;
      const decisaoTokens = decisaoContent
        .split(/\s+/)
        .slice(0, remainingTokens)
        .join(' ');

      totalTokens =
        ementaTokensCount + (decisaoTokens ? countTokens(decisaoTokens) : 0);

      return {
        ementaContent:
          ementaContent + (decisaoTokens ? ' ' + decisaoTokens + '...' : '...'),
        decisaoContent: '',
        totalTokens,
      };
    } else {
      const truncatedEmenta =
        ementaTokens.slice(0, maxTokens).join(' ') + '...';
      totalTokens = countTokens(truncatedEmenta);
      return {
        ementaContent: truncatedEmenta,
        decisaoContent: '',
        totalTokens,
      };
    }
  };

  useEffect(() => {
    const { ementaContent, decisaoContent, totalTokens } =
      getEmentaDecisaoContent();

    if (totalTokens !== maxTokens) {
      setMaxTokens(totalTokens);
    }
  }, [card, maxTokens]);

  const handleExpand = () => {
    setMaxTokens((prevMaxTokens) => prevMaxTokens + 100);
    setShowAllText(true);
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const handleReduce = () => {
    setShowAllText(false);
    setMaxTokens(150);
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const handleToggle = () => {
    if (showAllText) {
      handleReduce();
    } else {
      handleExpand();
    }
  };

  const handleScroll = useCallback(() => {
    const content = contentRef.current;
    if (content) {
      const isAtBottom =
        content.scrollTop + content.clientHeight >= content.scrollHeight - 1;
      if (isAtBottom && !showAllText) {
        setShowAllText(true);
      }
    }
  }, [showAllText]);

  useEffect(() => {
    const currentRef = contentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const renderSection = (title, content) => {
    if (title === 'Inteiro Teor') {
      return null; // Não renderiza "Inteiro Teor" dentro das outras seções
    }
    return (
      <>
        <h6 className="font-semibold">{title}</h6>
        <p
          className="p-text-result-card"
          dangerouslySetInnerHTML={{ __html: sanitizeContent(content) }}
        />
      </>
    );
  };

  const sectionsWithoutInteiroTeor = useMemo(() => {
    return (card.content || []).filter(
      (section) => section.title !== 'Inteiro Teor',
    );
  }, [card.content]);

  return (
    <div
      className={'flex flex-row'}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {!isInMonitoring && (
        <div>
          <div className="flex flex-row items-center">
            <Whisper
              trigger="hover"
              speaker={
                <Tooltip>{isSelected ? 'Descartar' : 'Selecionar'}</Tooltip>
              }
              placement="top"
            >
              <div
                className="flex"
                onClick={(e) => {
                  e.stopPropagation();
                  if (e.target.tagName !== 'INPUT') {
                    bulkactionCheckboxHandler(card.id);
                  }
                }}
              >
                <Checkbox
                  id={card.id}
                  className={`border-2 ${isSelected ? 'bg-transparent' : 'bg-white'} border-[#D0D5DD]`}
                  checked={selectedCards.some(
                    (selectedCard) => selectedCard.id === card.id,
                  )}
                />
              </div>
            </Whisper>
            <ArrowRight2
              size="16"
              color="#667085"
              className={`mr-3 ${isBeingHovered ? '' : 'invisible'}`}
            />
          </div>
        </div>
      )}
      <div
        className={cn(
          isInMonitoring
            ? 'tv-resultcard monitoring'
            : `tv-resultcard border-3 border-solid  ${isSelected ? 'border-blue-500' : isBeingHovered ? 'border-[#84CAFF]' : 'border-transparent'}`,
        )}
        onClick={(e) => {
          const selection = window.getSelection();
          if (selection && selection.toString().length > 0) {
            return;
          }

          const ignoredTags = ['INPUT', 'BUTTON', 'A', 'svg', 'path'];
          if (!ignoredTags.includes(e.target.tagName)) {
            bulkactionCheckboxHandler();
          }
        }}
      >
        <div id="tv-resultcard" className="gap-6 flex items-start">
          <Badge className="my-auto">{card.entity.name}</Badge>
          <div
            className="gap-2 flex flex-col"
            style={{ position: 'relative', zIndex: 0 }}
          >
            <h6 className="font-bold">{card.name}</h6>
            <div className="gap-6 flex">
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Relator</Tooltip>}
                placement="top"
              >
                <div className="gap-2 flex items-center">
                  <UserEdit size="22" color="#84CAFF" />
                  <span className="text-sm leading-5 font-medium text-[#667085]">
                    Relator:{' '}
                    {card?.relator?.name ? card.relator.name : 'Não Informado'}
                  </span>
                </div>
              </Whisper>

              <Whisper
                trigger="hover"
                speaker={<Tooltip>Data de publicação da decisão</Tooltip>}
                placement="top"
              >
                <div className="gap-2 flex items-center">
                  <Calendar size="22" color="#84CAFF" />
                  <span className="text-xs font-normal leading-5 text-[#667085]">
                    {' '}
                    {card?.dataPub ? card.dataPub : 'Não Informado'}
                  </span>
                </div>
              </Whisper>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Data de julgamento da decisão</Tooltip>}
                placement="top"
              >
                <div className="gap-2 flex items-center">
                  <Judge size="22" color="#84CAFF" />
                  <span className="text-xs font-normal leading-5 text-[#667085]">
                    {' '}
                    {card?.dataJul ? card.dataJul : 'Não Informado'}
                  </span>
                </div>
              </Whisper>

              {/* <span className="text-xs font-normal leading-5 text-[#98A2B3]">
                <Judge size="24" color="#84CAFF" />
                {card.dataJul}
              </span> */}
            </div>
            <div>
              {card.chips !== null ? (
                card.chips?.map((chip, index) => {
                  return (
                    <div
                      key={chip + index}
                      className="sub-buttons"
                      style={{ position: 'relative' }}
                    >
                      <FavorabilityModal key={index} chip={chip} card={card} />
                      {collectionId ? null : (
                        <MoreInfoResultCard
                          card={card}
                          collectionId={collectionId}
                          context={null}
                          segment={'tributario'}
                        />
                      )}
                      {collectionId ? (
                        <AddTagDecision
                          cards={[card]}
                          collectionId={collectionId}
                          collectionTags={collectionTags}
                          appearance="secondary"
                          icon={<Tag size="16" color="#84CAFF" />}
                          className="add-tag-container"
                        />
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <div className="sub-buttons" style={{ position: 'relative' }}>
                  <FavorabilityModal card={card} />
                  {collectionId ? null : (
                    <MoreInfoResultCard
                      card={card}
                      collectionId={collectionId}
                      context={null}
                      segment={'tributario'}
                    />
                  )}
                  {collectionId ? (
                    <AddTagDecision
                      cards={[card]}
                      collectionId={collectionId}
                      collectionTags={collectionTags}
                      appearance="secondary"
                      icon={<Tag size="16" color="#84CAFF" />}
                      className="add-tag-container"
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <ResultButtonGroup
            card={card}
            collectionId={collectionId}
            query={query}
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
          />
        </div>
        <div
          className={cn('tv-resultcard_content py-3', { expand: showAllText })}
          style={{
            height: showAllText
              ? `${contentRef.current?.scrollHeight}px`
              : '130px',
          }}
          ref={contentRef}
        >
          {sectionsWithoutInteiroTeor.map((section, index) => (
            <div className="pb-3" key={index}>
              {renderSection(section.title, section.content)}
            </div>
          ))}
        </div>
        {card.content.some((section) => section.title === 'Inteiro Teor') && (
          <ResultCardInteiroTeor
            content={
              card.content.find((section) => section.title === 'Inteiro Teor')
                .content
            }
            card={card}
          />
        )}
        <div className="tv-resultcard_footer pt-4">
          {/* sera utilizado futuramente (Timeline) {card.timeline && card.timeline !== null ? (
            <Button
              appearance="secondary"
              size="sm"
              onClick={() => setOpenTimeline(!openTimeline)}
              icon={
                !openTimeline ? (
                  <ArrowDown2 size={12} />
                ) : (
                  <ArrowUp2 size={12} />
                )
              }
            >
              Outros acórdãos deste processo
            </Button>
          ) : null} */}

          <ReadMoreOrLessButton
            showAllText={showAllText}
            setShowAllText={showAllText ? handleReduce : handleExpand}
            cardId={card.id}
            hasMoreText={maxTokens < (contentRef.current?.scrollHeight || 0)}
          />
        </div>
        {/* <div>
          {openTimeline ? <DecisionsTimeline timeline={card.timeline} /> : null}
        </div> */}
      </div>
    </div>
  );
};

import React from 'react';
import { Panel } from 'rsuite';
import PropTypes from 'prop-types';
import './TuriviusPanel.scss';

const TuriviusPanel = ({
  header = '',
  withShadow = true,
  withBorder = true,
  style = {},
  backgroundColor = 'white',
  children,
  className = '',
}) => (
  <Panel
    header={header}
    shaded={withShadow}
    bordered={withBorder}
    className={`turivius-panel ${className}`}
    style={{ ...style, backgroundColor }}
  >
    {children}
  </Panel>
);

TuriviusPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withShadow: PropTypes.bool,
  withBorder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  header: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default TuriviusPanel;

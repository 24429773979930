import { useState } from 'react';
import { AddCircle, TickCircle } from 'iconsax-react';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import './style.scss';
import { toast } from 'react-toastify';
import { useCollectionTags } from '../../../../../store/ducks/collectionTags';

const AddCardsToTagButton = ({ cards, collectionId, tag }) => {
  const [loading, setLoading] = useState(false);
  const { fetch: fetchCollectionTags } = useCollectionTags();

  function addCardsToTagHandler() {
    setLoading(true);
    const cardIds = cards.map((card) => card.id);

    requester.collections
      .addCardsToTag(tag.id, cardIds)
      .then(() => {
        fetchCollectionTags(collectionId);
        toast.success(
          <span className="text-base leading-7 text-[#475467]">
            {cards.length === 1
              ? 'Marcador adicionado'
              : 'Marcadores adicionados'}
          </span>,
          {
            icon: <TickCircle size="sm" color="#12B76A" />,
            className: 'w-[312px] right-[26px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#12B76A' },
          },
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button
      fullWidth
      onClick={addCardsToTagHandler}
      appearance="secondary"
      icon={
        loading ? (
          <SpinningLoader size="sm" color="gray" />
        ) : (
          <AddCircle size={16} color="#475467" />
        )
      }
      size="sm"
    >
      {'Adicionar'}
    </Button>
  );
};

export default AddCardsToTagButton;

import React from 'react';
import PropTypes from 'prop-types';
import './TuriviusLogin.scss';
import {
  toaster,
  Message,
  Button,
  ButtonToolbar,
  Content,
  FlexboxGrid,
  Form,
  Panel,
  Schema,
  Container,
  InputGroup,
  Input,
} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const loginModel = Schema.Model({
  email: Schema.Types.StringType()
    .isEmail('Por favor, informe um e-mail válido.')
    .isRequired('Este campo é obrigatório.'),
  password: Schema.Types.StringType().isRequired('Este campo é obrigatório.'),
});

const recoverPasswordModel = Schema.Model({
  email: Schema.Types.StringType()
    .isEmail('Por favor, informe um e-mail válido.')
    .isRequired('Este campo é obrigatório.'),
});

const TextField = ({ name, label, accepter, type, ...rest }) => (
  <Form.Group>
    <Form.ControlLabel>{label}</Form.ControlLabel>
    <Form.Control name={name} type={type} accepter={accepter} {...rest} />
  </Form.Group>
);

class TuriviusLogin extends React.Component {
  state = {
    loginFormValue: {},
    loginFormError: {},
    recoverPasswordFormValue: {},
    recoverPasswordFormError: {},
    visible: false,
  };

  handleChangeVisible = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleSubmitLogin = () => {
    if (this.loginForm.check()) {
      const { loginFormValue } = this.state;
      this.props.onLog(loginFormValue.email, loginFormValue.password);
    } else {
      toaster.push(
        <Message
          showIcon
          closable
          type="warning"
          duration={5000}
          header="Campos inválidos"
        >
          Preencha os campos e tente novamente
        </Message>,
      );
    }
  };

  handleSubmitRecoverPassword = () => {
    if (this.recoverPasswordForm.check()) {
      const { recoverPasswordFormValue } = this.state;
      this.props.onNewPassword(recoverPasswordFormValue.email);
    } else {
      toaster.push(
        <Message
          showIcon
          closable
          type="warning"
          duration={5000}
          header="Campos inválidos"
        >
          Preencha os campos e tente novamente
        </Message>,
      );
    }
  };

  keyPressLogin = (e) => {
    console.log(this.state.loginFormValue);
    if (e.key === 'Enter') {
      this.handleSubmitLogin();
    }
  };

  keyPressRecoverPassword = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmitRecoverPassword();
    }
  };

  InputPassword = () => {
    return (
      <InputGroup inside>
        <Input
          type={this.state.visible ? 'text' : 'password'}
          onKeyPress={this.keyPressLogin}
        />
        <InputGroup.Button onClick={this.handleChangeVisible}>
          {this.state.visible ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} />
          )}
        </InputGroup.Button>
      </InputGroup>
    );
  };

  render() {
    const {
      page = 'login',
      logo = '',
      fetching = false,
      goLogin,
      goForgotPassword,
      inside,
      loginHeader,
      loginFooter,
    } = this.props;

    const { loginFormError, recoverPasswordFormError, visible } = this.state;
    return (
      <div className="login-page">
        <Container>
          <Content>
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item colspan={20} style={{ marginBottom: 20 }}>
                {loginHeader}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={20}>
                {inside ? null : (
                  <div
                    className={`logo-image ${inside ? 'inside' : 'outside'}`}
                  >
                    <img src={logo} alt="Turivius" />
                  </div>
                )}
                <Panel
                  className="box-login"
                  header={
                    inside ? (
                      <div>
                        <div
                          className={`logo-image ${inside ? 'inside' : 'outside'}`}
                        >
                          <img src={logo} alt="Turivius" />
                        </div>
                        <h4>{page === 'login' ? null : 'Recuperar Senha'}</h4>
                      </div>
                    ) : (
                      <div>
                        <h4>{page === 'login' ? null : 'Recuperar Senha'}</h4>
                      </div>
                    )
                  }
                >
                  {page === 'login' ? (
                    <div>
                      <Form
                        fluid
                        ref={(ref) => (this.loginForm = ref)}
                        onChange={(loginFormValue) => {
                          this.setState({ loginFormValue });
                        }}
                        onCheck={(loginFormError) => {
                          this.setState({ loginFormError });
                        }}
                        model={loginModel}
                        readOnly={fetching}
                        formError={loginFormError}
                      >
                        <Form.Group>
                          <Form.ControlLabel>E-mail</Form.ControlLabel>
                          <Form.Control
                            name="email"
                            onKeyPress={this.keyPressLogin}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.ControlLabel>Senha</Form.ControlLabel>
                          <Form.Control
                            name="password"
                            type="password"
                            onKeyPress={this.keyPressLogin}
                          />
                        </Form.Group>
                        {/* <TextField accepter={this.InputPassword} name="password" label="Senha" type="password"  onKeyPress={this.keyPressLogin}/> */}
                        <Form.Group>
                          <ButtonToolbar className="container-buttons">
                            <Button
                              block
                              className="btn-forgot"
                              appearance="link"
                              onClick={goForgotPassword}
                              disabled={fetching}
                            >
                              Esqueceu sua senha?
                            </Button>
                            <Button
                              block
                              className="btn-login"
                              onClick={this.handleSubmitLogin}
                              loading={fetching}
                            >
                              Entrar
                            </Button>
                          </ButtonToolbar>
                        </Form.Group>
                      </Form>
                    </div>
                  ) : (
                    <Form
                      fluid
                      ref={(ref) => (this.recoverPasswordForm = ref)}
                      onChange={(recoverPasswordFormValue) => {
                        this.setState({ recoverPasswordFormValue });
                      }}
                      onCheck={(recoverPasswordFormError) => {
                        this.setState({ recoverPasswordFormError });
                      }}
                      model={recoverPasswordModel}
                      readOnly={fetching}
                      formError={recoverPasswordFormError}
                    >
                      <Form.Group>
                        <Form.ControlLabel>E-mail</Form.ControlLabel>
                        <Form.Control
                          name="email"
                          onKeyPress={this.keyPressRecoverPassword}
                        />
                      </Form.Group>
                      <Form.Group>
                        <ButtonToolbar className="container-buttons">
                          <Button
                            block
                            className="btn-login"
                            onClick={this.handleSubmitRecoverPassword}
                            loading={fetching}
                          >
                            Pedir nova senha
                          </Button>
                          <Button
                            block
                            className="btn-login again"
                            onClick={goLogin}
                            disabled={fetching}
                          >
                            Fazer login
                          </Button>
                        </ButtonToolbar>
                      </Form.Group>
                    </Form>
                  )}
                </Panel>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={20} style={{ marginTop: 20 }}>
                {loginFooter}
                {/* </Panel> */}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Content>
        </Container>
      </div>
    );
  }
}

TuriviusLogin.propTypes = {
  page: PropTypes.string,
  logo: PropTypes.string,
  fetching: PropTypes.bool,
  recoverPasswordValue: PropTypes.object,
  recoverPasswordError: PropTypes.object,
  loginFormError: PropTypes.object,
  loginFormValue: PropTypes.object,
  onNewPassword: PropTypes.func,
  onLogIn: PropTypes.func,
  loginHeader: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loginFooter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TuriviusLogin;

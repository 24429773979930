import { ClipboardClose, CloseCircle } from 'iconsax-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import './style.scss';
import { useCollectionTags } from '../../../../../store/ducks/collectionTags';

const RemoveCardsFromTagButton = ({
  children,
  cards,
  collectionId,
  activeTagsToCardsMap,
  numTheCardsWithTag,
  fullWidth,
  appearance,
  icon,
  className,
}) => {
  const [loading, setLoading] = useState(false);
  const { fetch: fetchCollectionTags } = useCollectionTags();

  function removeCardsFromTagHandler() {
    setLoading(true);
    let removePromises = [];
    activeTagsToCardsMap.forEach((item) => {
      removePromises.push(
        requester.collections.removeCardsToTag(item.tagId, item.cardIds),
      );
    });

    Promise.all(removePromises)
      .then(() => {
        fetchCollectionTags(collectionId);
        toast.error(
          <span className="text-base leading-7 text-[#475467]">
            {cards.length === 1 ? 'Marcador removido' : 'Marcadores removidos'}
          </span>,
          {
            icon: <ClipboardClose size="sm" color="#F04438" />,
            className: 'w-[312px] right-[26px] cursor-pointer bottom-[50px]',
            progressStyle: { background: '#F04438' },
          },
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button
      fullWidth={fullWidth}
      onClick={removeCardsFromTagHandler}
      appearance={appearance}
      icon={loading ? <SpinningLoader size="sm" color="gray" /> : icon}
      size="sm"
      className={className}
    >
      <div className="flex flex-row items-center justify-center">
        <span className="hide-text">{children}</span>
        {numTheCardsWithTag != null && (
          <div className="ml-2 px-3 font-semibold rounded-xl bg-red-100 text-red-500">
            {numTheCardsWithTag}
          </div>
        )}
      </div>
    </Button>
  );
};

export default RemoveCardsFromTagButton;

import { useEffect } from 'react';
import { Toggle } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import { Tooltip, Whisper } from 'rsuite';
import './SynonymsFilter.scss';

const SynonymsFilter = ({ context, filtersProps, AllProps, clear }) => {
  function onChangeFilter(option, type) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: type,
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    switch (type) {
      case 'synonyms':
        filtersProps?.changeSynonyms(option);
        break;
      case 'recursosRepetitivos':
        filtersProps?.changeRecursosRepetitivos(option);
        break;
      case 'repercussaoGeral':
        filtersProps?.changeRepercussaoGeral(option);
        break;
      default:
        break;
    }
  }

  useEffect(() => {}, [filtersProps]);

  useEffect(() => {
    if (clear) {
      filtersProps?.changeSynonyms(true);
    }
  }, [clear]);

  return (
    <div className="synonyms-filter-styles">
      <div className="toggle-section">
        <label className="synonyms-toggle-label">
          Incluir Termos Semelhantes
        </label>
        <Toggle
          onChange={(value) => onChangeFilter(value, 'synonyms')}
          checked={filtersProps?.synonyms}
          disabled={false}
        />
      </div>
      <hr />
      <div className="toggle-group">
        <label>
          Buscar apenas{' '}
          <Whisper
            trigger="hover"
            placement="top"
            speaker={
              <Tooltip>Em breve esse filtro estará disponível para uso</Tooltip>
            }
          >
            <div className="py-0.5 px-2 max-h-5 inline-flex justify-center items-center bg-[#FDB022] text-white font-semibold rounded-full cursor-default">
              Em breve
            </div>
          </Whisper>
        </label>

        <div className="toggle-item">
          <h3>Recursos Repetitivos</h3>
          <div className="box-toggle">
            <Toggle
              onChange={(value) => onChangeFilter(value, 'recursosRepetitivos')}
              checked={false}
              disabled={false}
            />
            <span>
              {AllProps?.generalCount?.rr
                ? AllProps?.generalCount?.rr.reduce(
                    (acc, curr) => acc + (curr?.count ? curr.count : 0),
                    0,
                  )
                : 0}
            </span>
          </div>
        </div>
        <div className="toggle-item">
          <h3>Repercussão Geral</h3>
          <div className="box-toggle">
            <Toggle
              onChange={(value) => onChangeFilter(value, 'repercussaoGeral')}
              checked={false}
              disabled={false}
            />
            <span>
              {AllProps?.generalCount?.rg
                ? AllProps?.generalCount?.rg.reduce(
                    (acc, curr) => acc + (curr?.count ? curr.count : 0),
                    0,
                  )
                : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SynonymsFilter;

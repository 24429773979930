import React, { useState, useRef, useEffect } from 'react';
import { Popover, Whisper, Input, Message, toaster } from 'rsuite';
import OperatorsHelper from '../OperatorsHelper';
import { requester } from '../../../../../requesters';
import { SearchNormal1, People } from 'iconsax-react';
import OperatorsHelperOpen from '../OperatorsHelperOpen';

const SEARCH_MODE = {
  NORMAL: 'normal',
  PROCESS_NUMBER: 'nproc',
};

const OptionListcollectionHistoryOptions = ({
  options,
  setQuery,
  selectedIndex,
}) => {
  return (
    <>
      {options.map((option, index) => {
        const Icon =
          option._source.kind === 'collection_history' ? SearchNormal1 : People;

        const isSelected = index === selectedIndex;

        return (
          <div
            key={option._id || index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`option-item ${isSelected ? 'selected' : ''}`}
            onClick={() => setQuery(option.text)}
          >
            <Icon size="18" />
            {option.text}
          </div>
        );
      })}
    </>
  );
};

const OptionListotherOptions = ({
  options,
  setQuery,
  selectedIndex,
  historyLength,
}) => {
  return (
    <>
      {options.map((option, index) => {
        const Icon =
          option._source.kind === 'collection_history' ? SearchNormal1 : People;

        const isSelected = index + historyLength === selectedIndex;

        return (
          <div
            key={option._id || index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            className={`option-item ${isSelected ? 'selected' : ''}`}
            onClick={() => setQuery(option.text)}
          >
            <Icon size="18" />
            {option.text}
          </div>
        );
      })}
    </>
  );
};

const SearchInput = ({ queryError, setQuery, query, placeholder }) => {
  const [options, setOptions] = useState([]);
  const searchHistoryRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [initialQuery, setInitialQuery] = useState('');
  const userId = localStorage.getItem('user') || 0;
  const companyId = localStorage.getItem('company') || 0;

  // Para controlar o debounce
  const debounceTimerRef = useRef(null);

  useEffect(() => {
    setQuery('');
  }, [placeholder, setQuery]);

  const doSearch = (searchValue) => {
    // Se query estiver vazia, limpa as opções
    if (!searchValue.trim()) {
      setOptions([]);
      return;
    }

    // Captura o valor que está sendo buscado localmente
    const localValue = searchValue;

    requester.cards
      .responseSeacrhInput({
        query: localValue,
        userId: userId,
        companyId: companyId,
      })
      .then((response) => {
        // Verifica se o que retornou ainda corresponde à última query digitada
        // Se for diferente, ignoramos esse resultado.
        // if (localValue === query) {
        setOptions(Array.isArray(response) ? response : []);
        // }
      })
      .catch((err) => {
        console.error(err);
        // Mesmo tratamento: só limpa as opções se ainda for a query atual
        if (localValue === query) {
          setOptions([]);
        }
      });
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    setSelectedIndex(-1);

    // Se já tiver um timeout pendente, limpa
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Se apertou espaço no final, busca imediatamente
    if (value.endsWith(' ')) {
      doSearch(value);
    } else {
      // Caso contrário, aguarda 500ms depois que o usuário parar de digitar
      debounceTimerRef.current = setTimeout(() => {
        doSearch(value);
      }, 500);
    }
  };

  const addOperator = (operator) => {
    const inputElement = document.getElementById('home-page-search-input');
    const cursorPosition = inputElement.selectionStart;
    let newQuery = query;

    newQuery =
      operator === '$'
        ? `${newQuery.substring(0, cursorPosition)}${operator}${newQuery.substring(cursorPosition)}`
        : `${newQuery.substring(0, cursorPosition)} ${operator} ${newQuery.substring(cursorPosition)}`;

    newQuery = newQuery.replace('  ', ' ');
    setQuery(newQuery);
    inputElement.focus(); // Focus de volta no input
    setTimeout(() => {
      inputElement.setSelectionRange(
        cursorPosition + operator.length + 1,
        cursorPosition + operator.length + 1,
      );
    }, 200);
  };

  const handleKeyDown = (e) => {
    try {
      if (e.key === 'ArrowDown') {
        e.preventDefault();

        if (selectedIndex === -1) {
          setInitialQuery(query);
        }

        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex < options.length - 1 ? prevIndex + 1 : 0;
          if (options[nextIndex]) {
            setQuery(options[nextIndex].text);
          }
          return nextIndex;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();

        if (options.length === 0 || selectedIndex === -1) {
          return;
        }

        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : -1;
          if (nextIndex === -1) {
            setQuery(initialQuery);
          } else if (options[nextIndex]) {
            setQuery(options[nextIndex].text);
          }
          return nextIndex;
        });
      } else if (e.key === 'Enter') {
        if (selectedIndex >= 0 && selectedIndex < options.length) {
          setQuery(options[selectedIndex].text);
        } else {
          setQuery(query);
        }

        const searchTerm = query.trim();
        if (searchTerm.length >= 3) {
          document.getElementById('btn-search').click();
        } else {
          toaster.push(
            <Message duration={4000} type="warning" showIcon closable>
              Sua busca deve ter pelo menos três caracteres
            </Message>,
            { placement: 'topEnd' },
          );
        }
        setOptions([]);
        setSelectedIndex(-1);
      }
    } catch (error) {
      console.error('Erro no handleKeyDown: Linha 174 Sinput');
    }
  };

  const collectionHistoryOptions = options?.filter(
    (option) => option._source.kind === 'collection_history',
  );
  const otherOptions = options.filter(
    (option) => option._source.kind !== 'collection_history',
  );

  const hasSearchResults = options.length > 0;
  const home = true;
  const placeholderValue =
    placeholder === SEARCH_MODE.NORMAL
      ? 'Quais termos deseja buscar?'
      : 'Digite o número do processo ou do acórdão';
  const containerRef = useRef(null);
  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Whisper
        ref={searchHistoryRef}
        placement="bottom"
        trigger="click"
        container={containerRef.current}
        speaker={
          placeholder === SEARCH_MODE.NORMAL ? (
            <Popover
              container={null}
              id="search-history-popover"
              style={{
                width: document.getElementById('search-card-query-col')
                  ?.offsetWidth,
                left: '0!important',
              }}
            >
              <div className="search-result">
                <OptionListcollectionHistoryOptions
                  options={collectionHistoryOptions}
                  setQuery={setQuery}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              </div>
              {collectionHistoryOptions.length > 0 &&
                otherOptions.length > 0 && (
                  <div
                    style={{
                      padding: '4px',
                      color: '#98A2B3',
                      marginLeft: '4px',
                      fontSize: '10px',
                    }}
                  >
                    As pessoas também buscam por
                  </div>
                )}
              <div className="search-result" style={{ color: '#0D91FE' }}>
                <OptionListotherOptions
                  options={otherOptions}
                  setQuery={setQuery}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  // EXPLANATION: Se tiver sugestões de histórico ele passa para esse componente que irá posicionar as queries de usuário com index a mais
                  historyLength={collectionHistoryOptions.length}
                />
              </div>
              <hr />
              <OperatorsHelperOpen
                addOperator={addOperator}
                home={home}
                hasSearchResults={hasSearchResults}
              />
            </Popover>
          ) : (
            <div></div>
          )
        }
      >
        <Input
          id="home-page-search-input"
          data-testid="simple-home-page-search-input"
          className={`rs-custom-input-big ${queryError ? 'rs-custom-input-error' : ''}`}
          value={query}
          onChange={handleChangeQuery}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          placeholder={placeholderValue}
        />
      </Whisper>
    </div>
  );
};

export default SearchInput;

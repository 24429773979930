import { useEffect } from 'react';
import { ResultCard } from './ResultCard';
import ResultCardLoader from './ResultCardLoader';

const ResultCardsContainer = ({
  entities,
  results,
  loading,
  query,
  theMatchResultsThatHaveCollections,
}) => {
  const getEntityById = (entityId) => {
    const entitiesArr = Object.values(entities);
    return entitiesArr.find((entity) => entity.id === entityId);
  };

  const formatCardsValue = () => {
    const formattedCards = [];

    results?.map((card) => {
      const entity = getEntityById(Number(card.entity));
      formattedCards.push({
        ...card,
        id: card.id,
        entity: {
          id: card.entity,
          name: entity?.initials,
          jurisdiction: entity?.jurisdiction,
        },
        name: card.nome,
        relator: {
          id: card.relator_id,
          name: card.relator,
        },
        n_processo: card.n_processo,
        n_acordao: card.n_acordao,
        dataPub: card.data_pub,
        dataJul: card.data_jul,
        orgaoJulgador: card.orgao_julgador,
        content: card.content,
        url: card.url,
        chips: card.chips.length === 0 ? null : card.chips,
        timeline: card.timeline,
        sumarizable: card.sumarizable,
      });
    });
    return formattedCards;
  };

  useEffect(() => {
    formatCardsValue();
  }, []);

  return loading ? (
    <div className="result-container">
      <ResultCardLoader />
    </div>
  ) : (
    <div className="gap-4 flex flex-col overflow-y-auto max-h-auto py-2 pl-8 pr-10">
      {formatCardsValue()?.map((card) => {
        return (
          <ResultCard
            card={card}
            key={card.id}
            query={query}
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
          />
        );
      })}
    </div>
  );
};

export default ResultCardsContainer;

import React, { useState, useEffect } from "react";
import { Card } from '../../../../../ds-components';
import DateCardComponent from "./DateCardComponent";
import { requester } from '../../../../../../src/requesters';
import { Whisper, Tooltip } from "rsuite";
import { Turivius } from "../../../../../ui";


interface Articles {
    id: number,
    name: string,
    created_dt: string,
    desc: string,
    author: string,
    link: string
}

const RecentArticlesBox: React.FC = () => {
    const [articles, setArticles] = useState<Articles[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const isValidDate = (date: string) => {
        const parsedDate = Date.parse(date);
        return !isNaN(parsedDate);
    };

    const formatDate = (date: string) => {
        const [day, month, yearTime] = date.split('/');
        if (day && month && yearTime) {
            const [year, time] = yearTime.split(' ');
            if (year && time) {
                return new Date(`${year}-${month}-${day}T${time}`).toISOString();
            }
        }
        return 'Invalid Date';
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            setLoading(true);
            const response = await requester.cards.searchArticles();
            const formattedArticles = response.map((article: Articles) => ({
                ...article,
                created_dt: formatDate(article.created_dt)
            }));
            setArticles(formattedArticles);
        } catch (error) {
            console.error('Failed to fetch articles:', error);
            setError('Failed to fetch articles.');
        } finally {
            setLoading(false);
        }
    };

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <p>{error}</p>;
    // }

    const handleShowArticles = () => {
        window.open('https://turivius.com/portal/', '_blank');
    }


    return (
        <div className="recent-articles-box w-[415px] ">
            <Card title="">
                <div className="flex flex-col justify-between h-full ">
                    <div className="flex justify-between  ">
                        <h2 className="title-box ">Últimos artigos convidados</h2>
                        <div>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={<Tooltip>Ver todos os artigos</Tooltip>}
                            >
                                <div>
                                    <button onClick={handleShowArticles} className="text-blue-500 text-xs">Ver todos</button>
                                </div>
                            </Whisper>
                        </div>
                    </div>
                
                    <div className="flex-col gap-8 overflow-hidden">
                        {articles.length > 0 ? (
                            articles.map((article, index) => (
                                <React.Fragment key={index}>
                                    <div className="flex mb-3 cursor-pointer pt-8" onClick={() => window.open(article.link, '_blank')}>
                                        <DateCardComponent date={article.created_dt} />
                                        <Whisper
                                            placement="top"
                                            trigger="hover"
                                            speaker={<Tooltip>Ir para o artigo</Tooltip>}
                                        >
                                            <div className="text-xs ml-4 justify-center flex-1">
                                                <p className="text-xs font-semibold mt-4 truncate">{article.name}</p>
                                                <p className="text-xs font-semibold mt-4 mb-[2px] line-clamp-2">{article.desc} - Turivius</p>
                                                <p className="text-xxs text-gray-500  truncate">Autor: {article.author}</p>
                                            </div>
                                        </Whisper>
                                    </div>
                                    {index < articles.length - 1 && (
                                        <hr className="border-t border-gray-300" />
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <div className="flex-col items-center justify-center w-[415px]  h-[382px]">
                            {loading?
                                <div className="flex items-center justify-center w-full h-[380px]">
                                    <Turivius.SimplePreloader size='lg' />
                                </div>
                                
                            :(
                                <p>Nenhum artigo encontrado.</p>
                            )}
                            </div>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default RecentArticlesBox;

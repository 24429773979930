import { Button } from '../../../../../ds-components';
import resumeInteiroTeorIcon from '../../../../../assets/svgs/resume-inteiro-teor.svg';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { FloatingUI } from '../../../../../components/ui/float';
import documentNormalIcon from '../../../../../assets/svgs/document.normal.svg';
import { Copy, Dislike, Like1, TickCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { wait } from '../../../../../utils/wait';
import { cn } from '../../../../../lib/utils';
import { Tooltip, Whisper } from 'rsuite';
import {
  useFetchEntities,
  useFetchFilters,
  useFetchSummary,
  useSummaryFeedback,
} from '../../../../../api/cards';
import { toast } from 'react-toastify';
import { posthog } from '../../../../../requesters';
import { Profile } from '../../../../../@types/profile';
import { useLocation } from 'react-router-dom';
import MarkdownFormatter from '../../../../collections/components/Collection/ModalCollection/components/MarkdownFormatter';
import './ResumeInteiroTeor.scss'


type ResumeInteiroTeorProps = {
  card: {
    id: string;
    name: string;
    entity: {
      id: number;
    };
    sumarizable: boolean;
  };
};

export const ResumeInteiroTeor = ({
  card: {
    id,
    name,
    entity: { id: entityId },
    sumarizable: summarizable,
  },
}: ResumeInteiroTeorProps) => {
  const { data } = useFetchFilters();
  const { data: fetchEntitiesData } = useFetchEntities();
  const {
    data: fetchSummaryData,
    mutate,
    isPending,
    isSuccess,
    isError,
  } = useFetchSummary();
  const { mutate: mutateSummaryFeedback } = useSummaryFeedback();

  const { pathname } = useLocation();
  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const userId = useMemo(() => localStorage.getItem('user') as string, []);
  const profile = useMemo(
    () => JSON.parse(localStorage.getItem('profile') as string) as Profile,
    [],
  );

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [textToCopy, setTextToCopy] = useState<string | undefined>('');
  const [fullCopied, setFullCopied] = useState(false);
  const [like, setLike] = useState<'like' | 'unlike' | null>(null);

  const theOptions = useMemo(
    () => data?.find((filter) => filter.public_name === 'Selecione os órgãos'),
    [data],
  );

  const theText = useMemo(
    () => name + '\n\n' + fetchSummaryData?.summary_text,
    [name, fetchSummaryData],
  );

  const theEntitiyIds = useMemo(
    () => fetchEntitiesData?.map((entity) => entity.id) || [],
    [fetchEntitiesData],
  );

  const handleCopy = useCallback(async () => {
    if (!isSuccess) return;
    navigator.clipboard.writeText(textToCopy || '');
    setCopied(true);
    await wait(5000);
    setCopied(false);

    posthog.capture('resume-inteiro-teor', {
      action: 'copy-selected-text',
      card_id: id,
      entityName:
        theOptions?.options.find((option) => option.id === entityId)
          ?.initials || '',
      text: textToCopy,
    });

    setTextToCopy('');
  }, [isSuccess, textToCopy, theOptions]);

  const handleFullCopy = useCallback(async () => {
    if (!isSuccess) return;
    navigator.clipboard.writeText(theText);
    toast.success('Resumo da decisão copiado');
    setFullCopied(true);
    await wait(5000);
    setFullCopied(false);

    posthog.capture('resume-inteiro-teor', {
      action: 'copy-full-text',
      card_id: id,
      entityName:
        theOptions?.options.find((option) => option.id === entityId)
          ?.initials || '',
      text: theText,
    });
  }, [isSuccess, theText, theOptions]);

  const handleFeedback = useCallback(
    (action: 'like' | 'unlike') => {
      if (action === like) return;
      mutateSummaryFeedback({
        card_id: id,
        entity: entityId,
        user_id: userId,
        action,
      });
      setLike(action);

      posthog.capture('resume-inteiro-teor', {
        action: 'feedback',
        card_id: id,
        entityName:
          theOptions?.options.find((option) => option.id === entityId)
            ?.initials || '',
        feedback: action,
      });
    },
    [theOptions],
  );

  const tooltipText = useMemo(() => {
    switch (true) {
      case !summarizable:
        return 'Ainda estamos implementando o resumo para esse órgão';
      case summarizable && !theEntitiyIds.includes(entityId):
        return 'Ops! Não conseguimos resumir essa decisão';
      default:
        return 'Nossa IA faz o resumo da decisão';
    }
  }, [summarizable, entityId, fetchEntitiesData]);

  useEffect(() => {
    if (open) return mutate({ card_id: id, entity: entityId, user_id: userId });
    setCopied(false);
    setTextToCopy('');
    setFullCopied(false);
  }, [open]);

  useEffect(() => {
    if (!fetchSummaryData?.feedback) return setLike(null);
    setLike(fetchSummaryData.feedback);
  }, [fetchSummaryData]);

  useEffect(() => {
    setCopied(false);
  }, [textToCopy]);

  useEffect(() => {
    posthog.people.set({
      email: profile.email,
      company: profile.company.id,
      company_name: profile.company.name,
      name: profile.name,
      is_trial: !!profile.company.asaas_url?.match('^https://www.asaas.com/.*'),
    });
  }, []);

  const stopPropagationOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //evitar que o click do botão acione o bulk action
    event?.stopPropagation()
  };

  // useEffect(() => {
  //   if (!open) return;

  //   posthog.capture('resume-inteiro-teor', {
  //     action: 'opened',
  //     card_id: id,
  //     entityName:
  //       theOptions?.options.find((option) => option.id === entityId)
  //         ?.initials || '',
  //   });
  // }, [open, theOptions]);

  const disabledStyle = { fontWeight: 100};
  // verifica se o botão de resumir ficará desligado
  const summaryDisabled = !!summarizable || !theEntitiyIds.includes(entityId); 


  return (

    
    <Dialog modal={false} open={open} onOpenChange={setOpen} >
      <DialogTrigger asChild onClick={stopPropagationOnClick}>
        <a className="hover:no-underline" style={ !summaryDisabled ? {} :  disabledStyle } >
          <Button
            appearance="secondary"
            size="sm"
            iconPosition="left"
            icon={<img src={resumeInteiroTeorIcon}  style={{ filter: summaryDisabled ?'none' :  'grayscale(100%)' }} />}
            tooltipText={tooltipText}
            disabled={!summaryDisabled}
          >
            {isInMonitoring ? '' : 'Resumir decisão'}
          </Button>
        </a>
      </DialogTrigger>

      <DialogContent onClick={stopPropagationOnClick} className="DialogContentClass max-w-2xl">
        <DialogHeader>
          <DialogTitle className="gap-1 flex items-center text-start text-base font-poppins font-semibold">
            Resumo do Inteiro Teor
            {/* <Whisper
              trigger="hover"
              placement="top"
              speaker={
                <Tooltip>
                  Estamos em desenvolvimento e aprimoramento da nossa IA
                </Tooltip>
              }
            >
              <div className="py-0.5 px-2 max-h-6 inline-flex justify-center items-center bg-[#FDB022] text-white font-semibold rounded-full cursor-default">
                Beta
              </div>
            </Whisper> */}
          </DialogTitle>
          <DialogDescription className="text-[#475467] max-h-96 overflow-y-auto selection:bg-[#FDFF75] whitespace-pre-line">
            {isPending && (
              <div>
                <div className="gap-1.5 flex items-center mb-7">
                  <div className="w-4 h-4 bg-[#053AC8] rounded-full animate-bounce-zoom" />
                  <div className="w-[406px] h-4 bg-[#D0D5DD] animate-pulse" />
                </div>
                <div className="gap-3 flex flex-col">
                  {[...Array(6)].map((_, index) => (
                    <div
                      key={index}
                      className="w-full h-4 bg-[#F2F4F7] animate-pulse"
                    />
                  ))}
                </div>
              </div>
            )}
            {isSuccess && fetchSummaryData && (
              <MarkdownFormatter markdownText={theText} />
            )}
            {isError && <span>Não disponível.</span>}
          </DialogDescription>

          <FloatingUI getTextSelected={setTextToCopy}>
            <>
              <button
                onClick={handleCopy}
                disabled={copied}
                className="gap-2 flex items-center px-3 py-2 rounded border border-[#344054] text-white bg-[#101828] hover:opacity-100"
              >
                {copied ? (
                  <>
                    <TickCircle size={12} color="white" />
                    Copiado
                  </>
                ) : (
                  <>
                    <Copy size={12} color="white" />
                    Copiar
                  </>
                )}
              </button>
              <div className="left-1/2 absolute bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-4 h-4 bg-[#101828] border-r border-b border-solid border-[#344054]" />
            </>
          </FloatingUI>
        </DialogHeader>

        <hr className="my-2" />
        <div className="gap-[10px] flex justify-between">
          <Whisper
            trigger="hover"
            placement="top"
            speaker={
              <Tooltip>
                {fullCopied ? 'Copiado' : 'Copiar todo o resumo'}
              </Tooltip>
            }
          >
            <button
              className={cn(
                'max-w-fit h-9 py-2 px-4 border border-[#E4E7EC] rounded gap-2 flex items-center justify-center text-xs font-medium leading-5',
                fullCopied ? 'text-[#12B76A]' : 'text-[#0069F5]',
              )}
              onClick={handleFullCopy}
              disabled={fullCopied || !isSuccess}
            >
              {fullCopied ? (
                <>
                  <TickCircle size={16} color="#12B76A" />
                  Copiado
                </>
              ) : (
                <>
                  <img src={documentNormalIcon} />
                  Copiar resumo
                </>
              )}
            </button>
          </Whisper>
          <div className="gap-1.5 flex items-center">
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                onClick={() => handleFeedback('like')}
                disabled={!isSuccess}
              >
                <Like1
                  size={20}
                  color={like === 'like' ? '#0D91FE' : '#667085'}
                />
              </button>
            </Whisper>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Não gostei</Tooltip>}
            >
              <button
                className="p-2 rounded border border-[#E4E7EC] flex justify-center items-center"
                onClick={() => handleFeedback('unlike')}
                disabled={!isSuccess}
              >
                <Dislike
                  size={20}
                  color={like === 'unlike' ? '#FDB022' : '#667085'}
                />
              </button>
            </Whisper>
          </div>
        </div>
        <span className="font-poppins text-[10px] font-normal leading-4 text-[#98A2B3]">
          Toda IA pode cometer erros. Cheque informações importantes
        </span>
      </DialogContent>
      {open && <div className="backgroundIAOpen" />}
    </Dialog>
  );
};

import React from 'react';
import NotificationCenter from './index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as NotificationsActions } from '../../../../../store/ducks/notifications';
import { requester } from '../../../../../requesters';
import { Drawer } from 'rsuite';
import { CloseCircle, CopySuccess } from 'iconsax-react';
import NotificationOpenButton from './NotificationOpenButton';
import { Whisper, Tooltip } from 'rsuite';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.requesting = false;
    this.triggerRef = React.createRef();
  }

  state = {
    unread: null,
    openDrawer: false,
  };

  componentDidMount() {
    if (this.props.notifications.length === 0 && !this.requesting) {
      this.requesting = true;

      let channels = `ALL,U${localStorage.getItem(
        'user',
      )},C${localStorage.getItem('company')}`;

      requester.notifications
        .getNotifications(this.props.page, channels)
        .then((response) => {
          let notifications = response?.map((notification) => {
            const message = JSON.parse(notification.message);
            let date = new Date(parseInt(notification.timestamp));
            return {
              id: notification.id,
              title: message.title,
              subtitle: message.subtitle,
              message: message.message,
              new: notification.new,
              actions: message.actions?.map((action) => {
                return {
                  link: action.ref_link,
                  link_name: action.button_label,
                };
              }),
              category: message.category,
              icon: message.icon,
              date: `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`,
            };
          });
          this.props.setNotifications(notifications);
          this.props.setPage(2);
        })
        .catch((err) => console.error(err))
        .finally(() => (this.requesting = false));

      requester.notifications
        .getUnreadNotifications(channels)
        .then((response) => {
          this.setState({ unread: response.unread });
        })
        .catch((err) => console.error(err));
    }
  }

  loadMore = () => {
    if (!this.requesting) {
      this.requesting = true;
      let channels = `ALL,U${localStorage.getItem(
        'user',
      )},C${localStorage.getItem('company')}`;
      requester.notifications
        .getNotifications(this.props.page, channels)
        .then((response) => {
          let notifications = response?.map((notification) => {
            const message = JSON.parse(notification.message);
            let date = new Date(parseInt(notification.timestamp));
            return {
              id: notification.id,
              title: message.title,
              subtitle: message.message,
              message: message.message,
              new: notification.new,
              actions: message.actions?.map((action) => {
                return {
                  link: action.ref_link,
                  link_name: action.button_label,
                };
              }),
              category: 'Novos Resultados',
              icon: 'post_add',
              date: `${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`,
            };
          });
          this.props.setNotifications([
            ...this.props.notifications,
            ...notifications,
          ]);
          if (notifications.length !== 0) {
            this.props.incrementPage();
          }
        })
        .catch((err) => console.error(err))
        .finally(() => (this.requesting = false));
    }
  };

  markItemAsRead(item) {
    requester.notifications
      .markAsRead(localStorage.getItem('user'), item.id)
      .then((response) => {})
      .catch((err) => console.error(err));
  }

  markAllAsRead() {
    let channels = `ALL,U${localStorage.getItem(
      'user',
    )},C${localStorage.getItem('company')}`;
    requester.notifications
      .markAllAsRead(channels)
      .then((response) => {})
      .catch((err) => console.error(err));
  }

  setUnread = (unread) => {
    this.setState({ unread });
  };

  handleDrawer = () => {
    const { openDrawer } = this.state;

    this.setState({ openDrawer: !openDrawer });
  };

  onMarkAllAsRead() {
    let notifications = this.props.notifications?.map((notification) => {
      notification.new = false;
      return notification;
    });

    this.props.setNotifications(notifications);

    this.markAllAsRead();
  }

  render() {
    const { unread, openDrawer } = this.state;
    const { searchPage } = this.props;
    return (
      <>
        <Whisper
          placement="bottom"
          trigger="hover"
          speaker={<Tooltip>Fechar</Tooltip>}
        >
          <NotificationOpenButton
            onClick={this.handleDrawer}
            unread={unread}
            searchPage={searchPage}
          />
        </Whisper>
        <Drawer
          className="tv-drawer-default"
          size="sm"
          open={openDrawer}
          onClose={this.handleDrawer}
        >
          <Drawer.Header closeButton={false} className="notification-header">
            <div style={{ textAlign: 'end', width: '100%' }}>
              <Whisper
                placement="bottom"
                trigger="hover"
                speaker={<Tooltip>Fechar</Tooltip>}
              >
                <CloseCircle
                  size="24"
                  color="#98A2B3"
                  onClick={this.handleDrawer}
                  style={{ cursor: 'pointer' }}
                  data-testid="close-notifications"
                />
              </Whisper>
            </div>
            <div className="tv-drawer-default_header_title">
              <h6>Notificações</h6>
              <button
                className="tv-drawer-default_header_title_button-clear"
                onClick={() => this.onMarkAllAsRead()}
              >
                <CopySuccess color="#FDA29B" size={18} />
                <label>Marcar todas como lida</label>
              </button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="tv-drawer-default_body">
            <Whisper
              placement="bottom"
              trigger="hover"
              speaker={<Tooltip>Fechar</Tooltip>}
            >
              <NotificationCenter
                notifications={this.props.notifications}
                noNotificationText={'Nenhuma nova notificação'}
                onScrollBottom={this.loadMore}
                onItemClick={(item) => this.markItemAsRead(item)}
                onMarkAllAsReadClick={this.markAllAsRead}
                unread={unread}
                setUnread={this.setUnread}
                triggerRef={this.triggerRef}
                onCloseDrawer={this.handleDrawer}
              />
            </Whisper>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  page: state.notifications.page,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(NotificationsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
